// import { useState, useEffect } from 'react';
// import styled from "styled-components";
import { Button, Divider, Grid, Typography } from '@mui/material';
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import InfoIcon from '@mui/icons-material/Info';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import PaymentIcon from '@mui/icons-material/Payment';
import Enum from '../../enums/Enum';

export default function SettingsTab({
  appSetCurrentSettingsPage,
  handleToggleSidebar,
  pageName,
  icon
}) {

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ "&:hover": { background: Enum.WebsiteColors.LightGreen }, paddingBottom: '.5rem', paddingTop: '.5rem' }}
        onClick={() => { handleToggleSidebar(); appSetCurrentSettingsPage(pageName) }}
      >
        <Grid item xs={2}>
          {icon}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h7" component="div" style={{ paddingLeft: '.25rem' }}>
            {pageName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ChevronRightIcon style={{ float: 'right' }} />
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}



