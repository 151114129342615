import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import Enum from "../../enums/Enum";
import ApiService from "../../services/ApiService";


export default function DeletePostDialog({ setDialogOpen, reloadInternalPosts, postToDelete }) {

  const deletePost = () => {
    ApiService.deleteInternalPost({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'post': postToDelete,
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setDialogOpen(false)
        reloadInternalPosts(true)
      })
      .catch((err) => console.error(err))
  }

  return (
    <Dialog
      open={true}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="question-dialog-title"
    >
      <DialogTitle>
        Delete Confirmation
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 0 }}>
        Are you sure you want to delete this post?
      </DialogContent>
      <DialogActions style={{ marginRight: '1rem', marginBottom: '.5rem' }}>
        <Button
          onClick={() => setDialogOpen(false)}
          style={{ color: Enum.WebsiteColors.Green }}
        >
          Back
        </Button>
        <Button
          onClick={() => deletePost()}
          style={{ color: Enum.WebsiteColors.Maroon }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
