import * as React from 'react';
import styled from "styled-components";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import QuizRadioButton from './QuizRadioButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const BackButton = styled.div`
  width: 100%;
  display: grid;
  justify-content: left;
`;
const Question = styled.div`
  max-width: 75%;
  margin: 0 auto;
`;
const QuestionAnswerChoices = styled.div`
  margin-top: 1rem;
`;
const steps = [
  {
    description: `Question Load 1?`,
    jsx: (
      <QuizRadioButton />
    )
  },
  {
    description:
      'Context Load 1.',
  },
  {
    description: `Question Load 2`,
    jsx: (
      <QuizRadioButton />
    )
  },
];
export default function QuizStepper({ setQuizStarted }) {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = steps.length
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };
  return (
    <Box
      sx={{
        maxWidth: '80%',
        flexGrow: 1,
        background: 'white',
        fontFamily: 'Roboto',
        fontSize: '1.5rem',
        lineHeight: '2',
        margin: 'auto',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      <Box
        sx={{
          height: 255,
          m: '0 auto',
          p: 2
        }}
      >
        <BackButton>
          <Button
            sx={{
              color: 'black',
            }}
            startIcon={
              <ArrowBackIcon />
            }
            onClick={() => setQuizStarted(false)}
          >
            Exit Quiz
          </Button>
        </BackButton>
        <Question>
          {steps[activeStep].description}
        </Question>
        <QuestionAnswerChoices>
          {steps[activeStep].jsx}
        </QuestionAnswerChoices>
      </Box>
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  )
}