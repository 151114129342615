import React from "react";
import { HeaderContainer } from "../styles/HeaderContainer.styled";
import { usePageUpdate } from "../../PageContext";
import DefaultLogo from "../../resources/m21f-logo.png";
import { Button, Typography } from "@mui/material";
import Enum from "../../enums/Enum";

export default function Header({ isUserAuthenticated }) {
	const updatePage = usePageUpdate()

	return (
		<HeaderContainer onClick={() => { updatePage('home') }}>
			<div 
				style={{ 
					margin: 'auto', 
					borderRadius: '.5rem', 
					width: '200px', 
					marginTop: '.5rem' 
				}}
			>
				<a href="/home">
					<img
						src={DefaultLogo}
						alt="Modern 21 Family Logo"
						style={{
							width: '45%', 
							height: 'auto', 
							marginTop: '2.5rem'
						}}
					/>
				</a>
				<br/>
				{!isUserAuthenticated && 
					<Typography>
						<Button 
							style={{ 
								color: Enum.WebsiteColors.Green 
							}} 
							href="/login"
						>
							Signup or Login!
						</Button>
					</Typography>
				}
				<br/>
			</div>
		</HeaderContainer>
	)
}