import { Typography } from "@mui/material"
import Trait from "./components/Settings/Questions/Trait"

export const categoryToSubredditMap = new Map([
  ['Recommended', ['FamilyDesign']],
  ['Fertility', ['Fertility']],
  ['Planning', ['FAMnNFP']],
  ['Reproductive Tech', ['IVF']],
  ['Pregnancy', ['pregnant']],
  ['Logistics', ['Fertility']],
  ['Community', ['SingleDads', 'singlemoms']],
])

// This is hacky, mapping the current categories to the expected ones in the 
// topics table
export const topicMap = new Map([
  ['Recommended', 'Recommended'],
  ['Fertility', 'Natural Fertility'],
  ['Logistics', 'Planning & Logistics'],
  ['Reproductive Tech', 'Reproductive Technology'],
  ['Family Dynamics', 'Family Dynamics'],
  ['Legal', 'Legal'],
  ['Community', 'Community'],
])

export const QUESTION_MAP = [
  ["When are you looking to start a family?", ['Never', 'Sometime', 'Soon', 'Now!']],
  ["What is your favorite color?", ['Green', 'Pink', 'Blue']],
  ["What is your favorite band?", ['Good Charlotte', 'Green Day', 'The Strokes', 'The White Stripes']],
  ["Favorite food?", ['nachos', 'tacos', 'burritos']],
  ["Favorite sport?", ['basketball', 'football', 'soccer', 'tennis']],
  // ["Favorite shoe brand?", ['Nike', 'Adidas', 'Vans', 'New Balance']],
  // ["Favorite company?", ['EA', 'Activision', 'Disney', 'Satan']],
  // ["Something new?", ['5', 'b', 'c', 'd']],
  // ["Something new?", ['6', 'b', 'c', 'd']],
  ['Who is your favorite singer?', ['God', 'Jesus', 'The Holy Spirit']],
]

export const TOPICS = [
  'Recommended', 'Natural Fertility', 'Planning & Logistics',
  'Reprod. Tech', 'Legal', 'Family Dynamics',
]

export const MORE_ABOUT_YOU_QUESTIONS = [
  'Select Traits which you identify with most',
  'What\'s your favorite color?',
]

export const getMoreAboutYouJSX = (props, index) => {
  return [
    <>
      <Trait
        label="Select Trait"
        trait={props.firstTrait}
        selectedTraits={props.selectedTraits}
        smallWidth={props.matchesSmallestWidth}
        setTrait={(event) => props.setFirstTrait(event.target.value)}
      />
      <Trait
        label="Select Trait"
        trait={props.secondTrait}
        selectedTraits={props.selectedTraits}
        smallWidth={props.matchesSmallestWidth}
        setTrait={(event) => props.setSecondTrait(event.target.value)}
      />
      <br />
      <Trait
        label="Select Trait"
        trait={props.thirdTrait}
        selectedTraits={props.selectedTraits}
        smallWidth={props.matchesSmallestWidth}
        setTrait={(event) => props.setThirdTrait(event.target.value)}
      />
      <Trait
        label="Select Trait"
        trait={props.fourthTrait}
        selectedTraits={props.selectedTraits}
        smallWidth={props.matchesSmallestWidth}
        setTrait={(event) => props.setFourthTrait(event.target.value)}
      />
    </>,
  ][index]
}