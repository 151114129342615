import React from "react";
import Divider from '@mui/material/Divider';
import styled from "styled-components";

const DividerStyle = { 
  marginLeft: '1rem', 
  marginRight: '1rem'
}
const DividerText = styled.div`
	padding: 5px; 
	color: grey; 
	font-family: Roboto;
`;

export default function TextDivider({text}) {
  return (
    <Divider style={DividerStyle}>
      <DividerText>
        {text}
      </DividerText>
    </Divider>
  )
}