import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { LoginSignUpButton } from "../styles/LoginSignupButton.styled";

const SignupCardContainer = styled.div`
  text-align: center;
`;
const SignupCardInnerContainer = styled.div`
  display: inline-block; 
  margin: 0 auto;
  width: 90%;
  padding: '1rem'
`;

export default function SignupCardContent({ usernameSignupText, handleUsernameSignupFieldChanged, 
  emailSignupText, handleEmailSignupFieldChanged, passwordSignupText, handlePassswordSignupFieldChanged, retypePasswordText,
  handleRetypePassswordFieldChanged, submitRegister }) {
  
  const [emailFieldHasError, setEmailFieldHasError] = useState(false)
  const [emailFieldErrorText, setEmailFieldErrorText] = useState('')
  const [usernameFieldHasError, setUsernameFieldHasError] = useState(false)
  const [usernameFieldErrorText, setUsernameFieldErrorText] = useState('')
  const [passwordFieldHasError, setPasswordFieldHasError] = useState(false)
  const [passwordFieldErrorText, setPasswordFieldErrorText] = useState('')
  const [retypeFieldHasError, setRetypeFieldHasError] = useState(false)
  const [retypeFieldErrorText, setRetypeFieldErrorText] = useState('')
  const [matchesSmallHeight, setMatchesSmallHeight] = useState(
    window.matchMedia("(max-height: 725px)").matches
  )
  const TextFieldStyle = {
    marginLeft: '0px', 
    marginBottom: matchesSmallHeight ? '.25rem' : '1.25rem',
  }
  const LoginSignupLabelTypography = {
    textAlign: 'left',
    fontFamily: 'Roboto', 
    color: 'gray', 
    marginBottom: matchesSmallHeight ? '1rem' : '1.5rem',
    marginTop: matchesSmallHeight ? '0px' : '1.5rem',
    fontWeight: 'bold',
  }
  
  useEffect(() => {
    window
      .matchMedia("(max-height: 725px)")
      .addEventListener('change', e => setMatchesSmallHeight( e.matches ))
  }, [])

  useEffect(() => {
    clearFields()
    if (passwordSignupText.length < 6) {
      setPasswordFieldHasError(true)
      setPasswordFieldErrorText('Username must be at least 6 characters.')
    }
  }, [passwordSignupText])

  useEffect(() => {
    clearFields()
    if (retypePasswordText !== passwordSignupText) {
      setRetypeFieldHasError(true)
      setRetypeFieldErrorText('Passwords must match.')
    }
  }, [retypePasswordText])

  useEffect(() => {
    clearFields()
    if (emailSignupText !== '' && !isValidEmail()) {
      setEmailFieldHasError(true)
      setEmailFieldErrorText('Email must be valid.')
    }
  }, [emailSignupText])

  function isValidEmail() {
    var re = /\S+@\S+\.\S+/;
    return re.test(emailSignupText)
  }

  function clearFields() {
    setPasswordFieldErrorText('')
    setEmailFieldErrorText('')
    setRetypeFieldErrorText('')
    setUsernameFieldErrorText('')
    setPasswordFieldHasError(false)
    setEmailFieldHasError(false)
    setRetypeFieldHasError(false)
    setUsernameFieldHasError(false)
  }

  return (
    <CardContent>
      <SignupCardContainer>
        <SignupCardInnerContainer>
          <Typography variant="h5" component="div" style={LoginSignupLabelTypography}>
              Sign Up
          </Typography>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              id="username-signup-text"
              label="Enter Username"
              defaultValue=""
              error={usernameFieldHasError}
              helperText={usernameFieldErrorText}
              style={TextFieldStyle}
              value={usernameSignupText}
              onChange={handleUsernameSignupFieldChanged}
            />
            <TextField
              fullWidth
              id="email-signup-text"
              label="Enter Email"
              defaultValue=""
              error={emailFieldHasError}
              helperText={emailFieldErrorText}
              style={TextFieldStyle}
              value={emailSignupText}
              onChange={handleEmailSignupFieldChanged}
            />
            <TextField
              fullWidth
              id="password-signup-text"
              label="Enter Password"
              defaultValue=""
              error={passwordFieldHasError}
              helperText={passwordFieldErrorText}
              style={TextFieldStyle}
              type="password"
              value={passwordSignupText}
              onChange={handlePassswordSignupFieldChanged}
            />
            <TextField
              fullWidth
              id="retype-password-text"
              label="Retype Password"
              defaultValue=""
              error={retypeFieldHasError}
              helperText={retypeFieldErrorText}
              style={TextFieldStyle}
              type="password"
              value={retypePasswordText}
              onChange={handleRetypePassswordFieldChanged}
            />
          </Box>
          <Button variant="contained" style={LoginSignUpButton} onClick={submitRegister}>Sign Up</Button>
        </SignupCardInnerContainer>
      </SignupCardContainer>
    </CardContent>
  )
}