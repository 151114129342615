import React from 'react';
import { Snackbar as MuiSnackbar, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export default function Snackbar({
  snackbarOpen,
  showSuccessMessage,
  autoHideDuration,
  handleCloseSnackbar,
}) {
  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Stack
      spacing={2}
      sx={{ width: '100%' }}
    >
      <MuiSnackbar
        open={snackbarOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseSnackbar}
        sx={{ marginLeft: 'calc(50% - 150px)' }}
      >
        {showSuccessMessage ?
          <Alert style={{ marginTop: 'auto' }} onClose={handleCloseSnackbar} severity="success">
            Profile successfully updated!
          </Alert>
          :
          <Alert style={{ marginTop: 'auto' }} onClose={handleCloseSnackbar} severity="error">
            Failed to update profile!
          </Alert>
        }
      </MuiSnackbar>
    </Stack>
  )
}
