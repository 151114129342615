import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Button, IconButton } from "@mui/material";

const FilterTagTextField = {
	marginTop: '.5rem',
	marginBottom: '.5rem',
	width: '98%',
	maxWidth: '800px',
}
const Tag = styled.span`
	margin-left: 5px;
	margin-bottom: .4rem;
	padding: 0 .5rem;
	border: 1px solid gray;
	color: white;
	background: ${props => (props.color)}; 
	align-items: center;
	height: fit-content;
	display: inline-block;
	font-size: 1rem;
`;
const TagClear = {
	color: 'rgb(36, 107, 124)',
	marginBottom: '.2rem',
	marginLeft: '.5rem',
}
const CloseIconStyle = {
	paddingLeft: '.1875rem',
	paddingRight: '0px',
	marginRight: '0px',
	fontSize: '1rem',
}
const TagContainer = styled.div`
	margin-left: 1%; 
	margin-right: 10px;
`;

export default function TopicPageInputForm({
	appviewTags,
	appviewSetTags,
	color,
	topicName,
	matchesSmallestWidth
}) {
	const [filterFieldValue, setFilterFieldValue] = useState('')

	const handleEnterKeyPressed = e => {
		if (e.keyCode === 13) {
			e.preventDefault()
			if (filterFieldValue.trim() === '') return
			if (appviewTags.includes(filterFieldValue)) return

			setFilterFieldValue('')
			appviewSetTags([...appviewTags, filterFieldValue])
		}
	}

	const handleDeletePressed = (e, tagToDelete) => {
		const updatedTags = new Set(appviewTags);
		updatedTags.delete(tagToDelete);
		appviewSetTags(updatedTags);
	}

	return (
		<>
			{/* <TextField */}
			{/* 	id="filter-tags" */}
			{/* 	label={"Search " + topicName} */}
			{/* 	variant="outlined" */}
			{/* 	style={FilterTagTextField} */}
			{/* 	sx={{ color: 'black' }} */}
			{/* 	value={filterFieldValue} */}
			{/* 	onKeyDown={handleEnterKeyPressed} */}
			{/* 	onChange={(e) => setFilterFieldValue(e.target.value)} */}
			{/* /> */}
			<TagContainer>
				{appviewTags && Array.from(appviewTags).map((tag) =>
					<Tag color={color} key={tag}>
						{tag.length <= 10 ? tag : tag.substring(0, 10) + '...'}
						<IconButton
							style={CloseIconStyle}
							onClick={(e) => { handleDeletePressed(e, tag) }}
						>
							<CloseIcon sx={{ color: 'white', '&:hover': { color: 'lightgrey' } }} />
						</IconButton>
					</Tag>
				)}
				{appviewTags.length > 0 &&
					<Button style={TagClear} onClick={() => {
						appviewSetTags([])
					}}>
						Clear
					</Button>
				}
			</TagContainer>
		</>
	)
}
