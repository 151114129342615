import React, { useState, useEffect } from "react";
import './App.css';
import AppView from './components/AppView/AppView';
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Settings from './components/Settings/Settings';
import Admin from "./components/Admin/Admin";
import ApiService from "./services/ApiService";
import { PageProvider } from "./PageContext";

function App() {
  const [theme, setTheme] = useState('Default')
  const [isAdmin, setIsAdmin] = useState(false)
  const [currentSettingsPage, setCurrentSettingsPage] = useState('Personalization')

  const authToken = localStorage.getItem('token');

  useEffect(() => {
    const settingsPage = localStorage.getItem('SettingsPage')
    if (settingsPage) {
      setCurrentSettingsPage(settingsPage)
      localStorage.removeItem('SettingsPage')
    }

    getIsAdmin()
      .then((isAdmin) => setIsAdmin(isAdmin))
  }, [])

  const updateCurrentSettingsPage = (page) => {
    setCurrentSettingsPage(page)
  }

  const getIsAdmin = async () => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    if (authToken == null) return;  // only try to set admin for logged in user
    try {
      const res = await ApiService.getIsAdminUser(options)
      return await res.json()
    } catch (err) {
      return console.error(err)
    }
  }

  return (
    <PageProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AppView
                appTheme={theme}
                appSetTheme={setTheme}
              />
            }
          />
          <Route
            path="login"
            element={
              <Login />
            }
          />
          <Route
            path="home"
            element={
              <Home
                updateCurrentSettingsPage={updateCurrentSettingsPage}
              />
            }
          />
          <Route
            path="settings"
            element={
              <Settings
                appTheme={theme}
                appCurrentSettingsPage={currentSettingsPage}
                appSetCurrentSettingsPage={setCurrentSettingsPage}
              />
            }
          />
          <Route
            path="admin"
            element={
              isAdmin ?
                <Admin />
                :
                <Home
                  appUpdateCurrentSettingsPage={updateCurrentSettingsPage}
                />
            }
          />
        </Routes>
      </BrowserRouter>
    </PageProvider>
  );
}

export default App;
