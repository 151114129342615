import React, { useContext, useState } from 'react'

const PageContext = React.createContext()
const PageUpdateContext = React.createContext()

export function usePage() {
	return useContext(PageContext)
}

export function usePageUpdate() {
	return useContext(PageUpdateContext)
}

export function PageProvider({ children }) {
	const [activePage, setActivePage] = useState('home')

	function updatePage(page) {
		setActivePage(page)
	}

	return (
		<PageContext.Provider value={activePage}>
			<PageUpdateContext.Provider value={updatePage}>
				{children}
			</PageUpdateContext.Provider>
		</PageContext.Provider>
	)
}