import { FormControlLabel } from "@mui/material";
import Radio from '@mui/material/Radio';
import Enum from "../../../../enums/Enum";


export default function CheckBoxQuestion({ currElement, answerIndex, response, setResponse }) {

  const handleChange = (event) => {
    // TODO: can add free response into this `answerText` part
    const id = parseInt(event.target.dataset.idAnswer);
    setResponse({ ...response, idAnswer: id, answerText: null })
  }
  
  return (
    <FormControlLabel 
      key={answerIndex}
      value={currElement.id}
      label={currElement.body}
      sx={{ pl: 5, pr: 5 }}
      control={
        <Radio
          value={currElement.body}
          checked={response.idAnswer === parseInt(currElement.id)}
          onChange={handleChange}
          inputProps={
            {
              "data-id-answer": currElement.id,
            }
          }
          sx={{
            color: Enum.WebsiteColors.Green,
            '&.Mui-checked': {
              color: Enum.WebsiteColors.Green,
            },
          }}
        />
      } 
    />
  )
}