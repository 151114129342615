import React, { useState, useEffect } from "react";
import TopicPageContentSection from "./TopicPageContentSection";
import styled from "styled-components";
import { Typography } from "@mui/material";
import ScaleLoader from "react-spinners/ScaleLoader";
import BackgroundImage from "../../../resources/bg2.jpg";
import Enum from "../../../enums/Enum";

const TopicMainPageContainer = styled.div(({ showBackgroundImage }) => ({
  background: '#fcfcfc',
  backgroundImage: showBackgroundImage ? `url(${BackgroundImage})` : 'none',
  backgroundSize: 'cover',
  overflowY: 'hidden',
}))
const TopicPageMainSectionContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center;
`;

export default function TopicMainPanel({
  appTheme,
  appSetTheme,
  appviewTags,
  appviewSetTags,
  appviewPosts,
  appviewSetPosts,
  appviewReloadFeed,
  appviewSetReloadFeed,
  topicId,
  topicName,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [loaded, setLoaded] = useState(false)
  const [showBackgroundImage, setShowBackgroundImage] = useState(false)
  const [matchesMedWidth, setMatchesMedWidth] = useState(window.matchMedia("(max-width: 1500px)").matches)

  let color = (appTheme === 'Default') ? Enum.WebsiteColors.Green : appTheme === 'Maroon' ? Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet

  useEffect(() => {
    window.matchMedia("(max-width: 1500px)")
      .addEventListener('change', e => setMatchesMedWidth(e.matches))
  }, [])

  function toggleShowBackgroundImage() {
    setShowBackgroundImage(!showBackgroundImage)
  }

  const scrollToTop = () => {
    document.getElementById("scroller").scroll(0, 0)
  }

  return (
    <TopicMainPageContainer showBackgroundImage={showBackgroundImage}>
      <TopicPageMainSectionContainer>
        <TopicPageContentSection
          appTheme={appTheme}
          setAppTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicmainLoaded={loaded}
          topicmainSetLoaded={setLoaded}
          topicmainShowBackgroundImage={showBackgroundImage}
          topicmainToggleShowBackgroundImage={toggleShowBackgroundImage}
          scrollToTop={scrollToTop}
          color={color}
          topicId={topicId}
          topicName={topicName}
        />
        {!loaded &&
          <>
            <Typography variant="h6" component="div" sx={{ mt: matchesMedWidth ? '2rem' : '5rem', mb: '.5rem', textAlign: 'center' }}>
              Please wait while we find the best posts for you
            </Typography>
            <ScaleLoader
              color={color}
              loading={!loaded}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </>
        }
      </TopicPageMainSectionContainer>
    </TopicMainPageContainer>
  )
}
