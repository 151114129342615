import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { createTheme } from '@mui/material/styles';
import RedditPost from "./PostTypes/RedditPost";
import RedditComment from "./PostTypes/RedditComment";
import EmailPost from "./PostTypes/EmailPost";
import YoutubePost from "./PostTypes/YoutubePost";
import { Button } from "@mui/material";
import FilterDialog from "../../Dialog/FilterDialog";
import { Card } from "@mui/material";
import Filter from "./Filter";
import TopicPageInputForm from "./TopicPageInputForm";
import NoResults from "./TopicPageUtil";
import QuestionStepper from "./SurveyQuestion/QuestionStepper";
import Enum from '../../../enums/Enum';
// import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import NavigationIcon from '@mui/icons-material/Navigation';
import InternalPost from "./PostTypes/InternalPost";
import InternalPostDialog from "../../Dialog/InternalPostDialog";
import { StrictMode } from 'react';

const AddButtonStyle = styled.div(() => ({
  position: 'sticky',
  bottom: 40,
  right: 20,
  zIndex: 100,
  float: 'right',
}))
const CategoryTitle = styled.div(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  fontFamily: 'Roboto',
  fontSize: '1.75rem',
  borderRadius: '5px',
  padding: '.2rem .5rem',
  paddingBottom: '1.5rem',
  paddingTop: '1rem',
  background: 'rgb(250, 250, 250)',
  width: '60%',
}))

export default function Feed({
  appTheme,
  appSetTheme,
  appviewTags,
  appviewSetTags,
  topicmainLoaded,
  topicmainShowBackgroundImage,
  topicmainToggleShowBackgroundImage,
  contentPage,
  contentHasNextPage,
  contentNumSignIns,
  contentAccountInfoReady,
  contentQuestionAnsweredInSession,
  contentSetQuestionAnsweredInSession,
  contentPosts,
  contentShowAll,
  contentShowVideos,
  contentShowReddit,
  contentShowEmails,
  contentSavedPosts,
  contentShowSavedPosts,
  contentMatchesMedWidth,
  contentMatchesSmallWidth,
  contentMatchesSmallestWidth,
  handleDropdownChange,
  handleCheckboxChange,
  getSavedPosts,
  loadMore,
  reloadInternalPosts,
  topicName,
  fromDialog,
}) {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const [questionStepperClosed, setQuestionStepperClosed] = useState(false)
  const [matchesLargeWidth, setMatchesLargeWidth] = useState(window.matchMedia("(min-width: 2000px)").matches)
  const [matchesMedHeight, setMatchesMedHeight] = useState(window.matchMedia("(max-height: 1230px)").matches)
  const [matchesSmallHeight, setMatchesSmallHeight] = useState(window.matchMedia("(max-height: 1075px)").matches)
  const [matchesSmallestHeight, setMatchesSmallestHeight] = useState(window.matchMedia("(max-height: 925px)").matches)
  let color = appTheme === 'Default' ? Enum.WebsiteColors.Green : appTheme === 'Maroon' ? Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet
  const [newPostDialogOpen, setNewPostDialogOpen] = useState(false)
  const [loadedPosts, setLoadedPosts] = useState([...contentPosts.values()]) // Map -> Array
  const [filteredPosts, setFilteredPosts] = useState([...contentPosts.values()]) // Map -> Array

  useEffect(() => {
    window.matchMedia("(min-width: 2000px)")
      .addEventListener('change', e => setMatchesLargeWidth(e.matches))
    window.matchMedia("(max-width: 1000px)")
    window.matchMedia("(max-height: 925px)")
      .addEventListener('change', e => setMatchesSmallestHeight(e.matches))
    window.matchMedia("(max-height: 1075px)")
      .addEventListener('change', e => setMatchesSmallHeight(e.matches))
    window.matchMedia("(max-height: 1230px)")
      .addEventListener('change', e => setMatchesMedHeight(e.matches))
  }, [])

  const FeedContainer = {
    scrollBehavior: 'smooth',
    textAlign: 'center',
    height: matchesSmallestHeight ? '85vh' : matchesSmallHeight ? '89vh' : matchesMedHeight ? '90vh' : '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: contentMatchesSmallestWidth ? '95%' : '85%',
    background: 'rgb(240, 240, 240)',
    padding: contentMatchesSmallWidth ? '0 1rem' : '0 3rem',
    border: 'thin solid grey',
    borderRadius: '.5rem',
    margin: '1rem auto',
    position: 'relative',
    left: matchesLargeWidth ? '-3rem' : '0rem',
    boxShadow: fromDialog ? 'none' : '10px 10px 10px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&::-webkit-scrollbar': {
      width: contentMatchesMedWidth ? 'initial' : '.5rem',
    },
    '&::-webkit-scrollbar-track': {
      background: contentMatchesMedWidth ? 'initial' : 'lightgrey'
    },
    '&::-webkit-scrollbar-thumb': {
      background: contentMatchesMedWidth ? 'initial' : 'grey',
      borderRadius: contentMatchesMedWidth ? 'initial' : '100vw',
      height: contentMatchesMedWidth ? 'initial' : '10rem'
    },
    zIndex: 1,
  }
  const ScrollToTopButton = {
    marginTop: '.2rem',
    marginBottom: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: color,
  }
  const TopicContainer = {
    width: contentMatchesMedWidth ? '100%' : 'calc(100% + 2.4rem)',
    marginLeft: contentMatchesMedWidth ? 'auto' : '-1.2rem',
    marginRight: contentMatchesMedWidth ? 'auto' : 'initial',
    position: contentMatchesMedWidth ? 'relative' : 'sticky',
    top: 0,
    borderBottom: 'thin solid #B0B0B0',
    borderRadius: '.5rem',
    border: 'thin solid gray',
    background: 'rgba(250, 250, 250, .8)',
    zIndex: 100
  }

  const scrollToTop = () => {
    document.getElementById("scroller").scroll(0, 0)
  }

  // update the loaded posts (posts for feed) whenever the posts update
  useEffect(() => {
    setLoadedPosts([...contentPosts.values()])
  }, [contentPosts])

  // set filtered posts, whenever the filters change, or the loaded posts are updated
  useEffect(() => {
    if (contentShowAll) {
      setFilteredPosts(loadedPosts)
    } else {
      if (!contentShowVideos) {
        setFilteredPosts(loadedPosts.filter((post) => {
          return post.source !== Enum.Sources.Youtube
        }))
      }
      if (!contentShowReddit) {
        setFilteredPosts(loadedPosts.filter((post) => {
          return post.source !== Enum.Sources.Reddit
        }))
      }
      if (!contentShowEmails) {
        setFilteredPosts(loadedPosts.filter((post) => {
          return post.source !== Enum.Sources.Gmail
        }))
      }
    }
  }, [contentShowVideos, contentShowEmails, contentShowReddit, contentShowAll, loadedPosts])

  const replaceSpecialCharacters = (st) => {
    return st.replaceAll("’", "'").replaceAll("“", "\"\"").replaceAll("”", "\"\"")
  }

  // appviewTags.forEach(tag => {
  //   let newPosts = filteredPosts.filter(post => post.source === Enum.Sources.Youtube ?
  //     (replaceSpecialCharacters(post.data.description?.toLowerCase()).includes(tag.toLowerCase()) ||
  //       replaceSpecialCharacters(post.data.title?.toLowerCase()).includes(tag.toLowerCase())) :
  //     (replaceSpecialCharacters(post.data.body?.toLowerCase()).includes(tag.toLowerCase()) ||
  //       replaceSpecialCharacters(post.data.title?.toLowerCase()).includes(tag.toLowerCase())))
  //   newPosts.forEach(newPost => {
  //     if (!posts.includes(newPost)) {
  //       setPosts(new Map(posts.set(newPost.id, newPost)))
  //     }
  //   })
  // })
  //
  // if (posts && props.tags.length === 0) {
  //   setPosts([...posts])
  // }


  // const updatePosts = () => {
  //   console.log("in feed", props.finalPosts)
  //   if (props.showSavedPosts) {
  //     setPosts(props.finalPosts)
  //     return
  //   }
  //   setPosts(props.finalPosts.filter(post => {
  //     return (post.source === Enum.Sources.Reddit && (props.showReddit || props.allContentTypesUnchecked)) ||
  //       (post.source === Enum.Sources.Gmail && (props.showEmails || props.allContentTypesUnchecked)) ||
  //       (post.source === Enum.Sources.Youtube && (props.showVideos || props.allContentTypesUnchecked) &&
  //         post.data.title !== 'Private video') ||
  //       (post.source === Enum.Sources.Internal)
  //   }))
  //   console.log("in feed after filter", posts)
  // }

  // useEffect(() => {
  //   updatePosts()
  // }, [props.finalPosts])

  // useEffect(() => {
  //   updateFinalPostEndIndex()
  // }, [posts, props.postEndIndex])

  // const updateFinalPostEndIndex = () => {
  //   setFinalPostEndIndex(props.postEndIndex > posts.length ? posts.length : props.postEndIndex)
  // }

  return (
    <Card sx={FeedContainer} id="scroller">
      <StrictMode>
        <div style={TopicContainer}>
          <CategoryTitle>
            {(topicName === Enum.Topics.Search || topicName === Enum.Topics.Saved) ? topicName : "Topic: " + topicName}
            <Filter
              fromDialog={false}
              appTheme={appTheme}
              appSetTheme={appSetTheme}
              topicmainLoaded={topicmainLoaded}
              topicmainShowBackgroundImage={topicmainShowBackgroundImage}
              topicmainToggleShowBackgroundImage={topicmainToggleShowBackgroundImage}
              contentMatchesSmallestWidth={contentMatchesSmallestWidth}
              handleDropdownChange={handleDropdownChange}
              handleCheckboxChange={handleCheckboxChange}
            />
            <TopicPageInputForm
              appviewTags={appviewTags}
              appviewSetTags={appviewSetTags}
              contentMatchesSmallestWidth={contentMatchesSmallestWidth}
              color={color}
              topicName={topicName}
            />
          </CategoryTitle>
        </div>
        {(topicmainLoaded && filteredPosts.length < 1) ?
          <NoResults
            topicmainLoaded={topicmainLoaded}
          />
          :
          <>
            {filterDialogOpen &&
              <FilterDialog
                appTheme={appTheme}
                appSetTheme={appSetTheme}
                topicmainLoaded={topicmainLoaded}
                topicmainShowBackgroundImage={topicmainShowBackgroundImage}
                topicmainToggleShowBackgroundImage={topicmainToggleShowBackgroundImage}
                contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                feedSetFilterDialogOpen={setFilterDialogOpen}
                handleDropdownChange={handleDropdownChange}
                handleCheckboxChange={handleCheckboxChange}
              />
            }
            {topicName === 'Recommended' && !questionStepperClosed && <>
              <QuestionStepper
                contentAccountInfoReady={contentAccountInfoReady}
                feedSetQuestionStepperClosed={setQuestionStepperClosed}
              />
            </>}
            {filteredPosts.map((post) =>
              <div style={{ textAlign: 'center', position: 'relative', zIndex: '10' }} key={"post" + post.id}>
                {post.source === Enum.Sources.Reddit && (contentShowReddit || contentShowAll) &&
                  <RedditPost
                    contentSavedPosts={contentSavedPosts}
                    contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                    post={post}
                    color={color}
                  />
                }
                {post.source === Enum.Sources.Reddit && post.data.comments?.length > 0 &&
                  (contentShowReddit || contentShowAll) &&
                  <div style={{ width: '95%', display: 'inline-block', margin: '0 auto' }}>
                    <RedditComment
                      contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                      post={post}
                    />
                  </div>
                }
                {post.source === Enum.Sources.Gmail &&
                  (contentShowEmails || contentShowAll) &&
                  <EmailPost
                    key={"post" + post.id}
                    contentSavedPosts={contentSavedPosts}
                    contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                    post={post}
                  />
                }
                {post.source === Enum.Sources.Youtube &&
                  (contentShowVideos || contentShowAll) &&
                  <YoutubePost
                    key={"post" + post.id}
                    contentSavedPosts={contentSavedPosts}
                    contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                    contentMatchesMedWidth={contentMatchesMedWidth}
                    post={post}
                  />
                }
                {post.source === Enum.Sources.Internal && topicName === 'Internal' &&
                  <InternalPost
                    contentSavedPosts={contentSavedPosts}
                    contentMatchesSmallestWidth={contentMatchesSmallestWidth}
                    color={color}
                    post={post}
                    reloadInternalPosts={reloadInternalPosts}
                  />
                }
              </div>
            )}
            {topicmainLoaded && <>
              {contentHasNextPage ?
                <Button
                  style={ScrollToTopButton}
                  onClick={loadMore}
                >
                  Load more
                </Button>
                :
                <Button
                  style={ScrollToTopButton}
                  onClick={scrollToTop}
                >
                  Back to top
                </Button>
              }
            </>}
          </>
        }
        {topicName === 'Internal' &&
          <AddButtonStyle>
            <Fab
              aria-label="add"
              sx={{
                background: Enum.WebsiteColors.Green,
                color: 'white',
                ":hover": {
                  color: Enum.WebsiteColors.Green,
                },
              }}
              onClick={() => setNewPostDialogOpen(true)}
            >
              <AddIcon />
            </Fab>
          </AddButtonStyle>
        }
        {newPostDialogOpen &&
          <InternalPostDialog
            setDialogOpen={setNewPostDialogOpen}
            reloadInternalPosts={reloadInternalPosts}
          />
        }
      </StrictMode>
    </Card>
  )
}
