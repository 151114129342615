import styled from "styled-components";

export const TopicButton = styled.div`
  background: ${props => (props.color)};
  color: #fff;
  border: none;
  position: relative;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: .5rem;

  @media (min-width: 1909px) {            
    height: 70px;
    font-size: 1.6em;
    margin-bottom: 5px;
  }

  @media (max-width: 1908px) {
    height: 70px;
    font-size: 1.5em;
    margin-bottom: 5px;
  }

  @media (max-width: 1736px) {
    height: 60px;
    font-size: 1.4em;
    margin-bottom: 5px;
  }

  &:hover {
    background: white;
    color: ${props => (props.color)};
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: ${props => (props.color)};
    transition: 400ms ease all;
  }

  &:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
  }

  &hover:before, &:hover:after {
    width: calc(100% - .5rem);
    margin-left: .25rem;
    transition: 800ms ease all;
  }
`