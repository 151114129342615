import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Fab, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField } from '@mui/material';
import ApiService from '../../services/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import ProfileQuestion from './ProfileQuestion';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MORE_ABOUT_YOU_QUESTIONS } from '../../Datasources';
import MuiAlert from '@mui/material/Alert';
import UploadIcon from '@mui/icons-material/Upload';
import Enum from '../../enums/Enum';

export default function Admin() {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [updateSuccessful, setUpdateSuccessful] = useState(false)
	const [autoHideDuration, setAutoHideDuration] = useState(1000)
  const [numProfileQuestion, setNumProfileQuestions] = useState(MORE_ABOUT_YOU_QUESTIONS.length)
  const [profileQuestions, setProfileQuestions] = useState(new Map([]))
  const [questionsUploadedFromFile, setQuestionsUploadedFromFile] = useState([])
  const [topic, setTopic] = useState()
  const [allTopics, setAllTopics] = useState([])
  const [subredditsInputText, setSubredditsInputText] = useState()
  const [subredditLinkResponse, setSubredditLinkResponse] = useState()

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  useEffect(() => {
    let options = {
			method: 'GET',
			headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token') 
      }
		}
    ApiService.getAllTopics(options)
      .then((res) => res.json())
      .then((data) => {
        for (let i=0; i<data.length; i++) {
          setAllTopics((previous) => [...previous, data[i]])
        }
      })
      .catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    MORE_ABOUT_YOU_QUESTIONS.forEach((value, i) => {
      updateProfileQuestions(i, value)
    })
  }, [MORE_ABOUT_YOU_QUESTIONS])

  function updateFields() {
    ApiService.updateDatasources({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token') 
      },
      body: JSON.stringify(Object.fromEntries(profileQuestions))
    })
    .then(() => {
      setUpdateSuccessful(true)
      setSnackbarOpen(true)
    })
    .catch((err) => console.error(err))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const updateProfileQuestions = (index, value) => {
    profileQuestions.set(index, value)
  }

  const mapTopicsTopSubreddits = () => {
    let subreddits = subredditsInputText.split(',')
    subreddits = subreddits.map(subreddit => subreddit.trim())
    ApiService.linkSubredditsToTopic({
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
			},
			body: JSON.stringify({
				'topic': topic,
				'subreddits': subreddits,
			})
		}).then(res => res.json())
    .then(data => {
      setSubredditLinkResponse(data)
    })
    .catch(err => console.error(err))
  }
  
  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', background: '#107ab0' }}>
      <Card sx={{ background: '#f2f1ed', width: '60%', height: '80vh', ml: 'auto', mr: 'auto', textAlign: 'center', mt: '2rem', position: 'relative' }}>
        <CardContent sx={{ width: '50%', ml: 'auto', mr: 'auto' }}>
          <div style={{ display: 'inline-block', float: 'left', position: 'absolute', left: 20 }}>
            <Button href="/" startIcon={<ArrowBackIcon />}></Button>
          </div>
          <Typography variant="h4" component="div" sx={{ mb: '1rem' }}>
            Welcome, Royalty!
          </Typography>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Settings {'>'} More about you {'>'} Profile Questions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {[...Array(numProfileQuestion)].map((e, i) => 
                <ProfileQuestion 
                  index={i}
                  profileQuestions={profileQuestions}
                  moreAboutYouQuestions={MORE_ABOUT_YOU_QUESTIONS}
                  updateProfileQuestions={updateProfileQuestions}
                  questionsUploadedFromFile={questionsUploadedFromFile}
                />
              )}
              <Fab 
                size="small" 
                color="primary" 
                sx={{ 
                  mt: 3, 
                  mr: 2, 
                  background: Enum.WebsiteColors.Green,
                  '&:hover': {
                    background: 'rgba(36, 107, 124, .8)'
                  }
                }} 
                aria-label="add question"
              >
                <UploadIcon sx={{ position: 'absolute', zIndex: -1 }} />
                <label style={{ 
                  display: 'inline-block',
                  width: '100%',
                  height: '100%',
                }}>
                  <input onChange={(event) => {
                    if (event.target.files) {
                      var file = event.target.files[0]
                      if (file) {
                        var reader = new FileReader()
                        reader.onload = function(event) {
                          let text = event.target.result
                          var lines = text.split(/[\r\n]+/g)
                          setNumProfileQuestions(lines.length)
                          lines.forEach((line, index) => {
                            updateProfileQuestions(index, line)
                            setQuestionsUploadedFromFile((previous) => [...previous, line])
                          })
                        }
                        reader.readAsText(file)
                      }
                    }
                  }} style={{ display: 'none' }} id="image-file" type="file" />
                </label>
              </Fab>
              <Fab 
                size="small" 
                color="primary" 
                sx={{ 
                  mt: 3, 
                  mr: 2,
                  background: Enum.WebsiteColors.Green,
                  '&:hover': {
                    background: 'rgba(36, 107, 124, .8)'
                  }
                }} 
                aria-label="remove question"
                onClick={() => {
                  if (numProfileQuestion > 1) {
                    setNumProfileQuestions((previous) => previous - 1)
                    profileQuestions.delete(profileQuestions.size - 1)
                  }
                }}
              >
                <RemoveIcon />
              </Fab>
              <Fab 
                size="small" 
                color="primary" 
                sx={{ 
                  mt: 3,  
                  background: Enum.WebsiteColors.Green,
                  '&:hover': {
                    background: 'rgba(36, 107, 124, .8)'
                  }
                }} 
                aria-label="add question"
                onClick={() => {
                  if (numProfileQuestion < 8) {
                    setNumProfileQuestions((previous) => previous + 1)
                  }
                }}
              >
                <AddIcon />
              </Fab>
              <Button 
                variant="contained" 
                sx={{ 
                  mt: '1.5rem', 
                  background: Enum.WebsiteColors.Green,
                  '&:hover': {
                    background: 'rgba(36, 107, 124, .8)'
                  },
                }} 
                fullWidth 
                onClick={() => updateFields()}
              >
                Submit Changes!
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Map Topics to Subreddits
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '50%', margin: 'auto' }}>
                <FormControl 
                  fullWidth
                >
                  <InputLabel 
                    id="topic-select-label"
                  >
                    Topic
                  </InputLabel>
                  <Select
                    labelId="topic-select-label"
                    id="topic-select"
                    value={topic}
                    sx={{
                      // width: '100%',
                      textAlign: 'left'
                    }}
                    label="Topic"
                    onChange={(e) => setTopic(e.target.value)}
                  >
                    {allTopics.map((topic, index) => (
                      <MenuItem 
                        id={index}
                        value={topic}
                      >
                        {topic}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div 
                style={{ 
                  marginTop: '1rem' 
                }}
              >
                <TextField
                  sx={{ 
                    width: '50%' 
                  }}
                  id="outlined-textarea"
                  label="Enter subreddits, separated by a comma"
                  placeholder="IVF, Family Design, singlemoms"
                  multiline
                  rows={4}
                  value={subredditsInputText}
                  onChange={(e) => setSubredditsInputText(e.target.value)}
                />
              </div>
              {subredditLinkResponse && 
                <TextField 
                  id="response-input" 
                  label="Result" 
                  variant="standard" 
                  multiline
                  sx={{ 
                    width: '50%',
                    mt: '1rem',
                  }}
                  style={{ color: 'black' }}
                  value={subredditLinkResponse}
                />
              }
              <Button 
                variant="contained" 
                sx={{ 
                  mt: '1.5rem', 
                  background: Enum.WebsiteColors.Green,
                  '&:hover': {
                    background: 'rgba(36, 107, 124, .8)'
                  },
                  width: '60%'
                }} 
                fullWidth 
                onClick={() => mapTopicsTopSubreddits()}
              >
                Submit Changes!
              </Button>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      <Stack 
				spacing={2} 
				sx={{ width: '100%' }}
			>
				<Snackbar 
					open={snackbarOpen} 
					autoHideDuration={autoHideDuration} 
					onClose={handleClose} 
					sx={{marginLeft: 'calc(50% - 150px)'}}
				>
					{updateSuccessful && 
						<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
							Data successfully updated!
						</Alert>
          }
				</Snackbar>
    	</Stack>
    </div>
  )
}