import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import data1 from "../../resources/data1.png";
import data2 from "../../resources/data2.png";
import data3 from "../../resources/data3.png";

const TextPanelContainer = styled.div`
	background-color: #f6f6f6;
`
const GridContainer = styled.div(({ md }) => ({
	display: 'grid',
	gridTemplateColumns: md ? 'none' : 'auto auto auto',
	gridTemplateRows: md ? 'auto auto auto' : 'none',
	gridGap: md ? '2rem' : 0
}))
const GridItem = styled.div(({ sm }) => ({
	textAlign: 'center',
	marginTop: '2rem',
	paddingLeft: sm ? '1rem' : '6rem',
	paddingRight: sm ? '1rem' : '6rem',
	paddingBottom: '1rem',
}))

export default function TextPanel({ homeMatchesSmallestWidth, homeMatchesMedWidth }) {

	return (
		<TextPanelContainer>
			<GridContainer md={homeMatchesMedWidth}>
				<GridItem sm={homeMatchesSmallestWidth}>
					<Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
						Family Design
					</Typography>
					<Typography style={{ fontSize: 'clamp(16px, 2vw, 20px)', marginTop: '.5rem', lineHeight: 1.25 }} component="div">
						Everyone has an oppertunity to shape their unique family journey. Families come in all shapes and sizes, 
						reflecting the rich tapestry of human experiences across the globe and throughout history. Deciding what your journey will look like
						and taking steps throughout your journey can take so much time and effort! What is best for you, and how do you get there? 
						We're here to help everyone navigate these questions, quickly, compassionately, and decisively. 
						
					</Typography>
					<img
						src={data1}
						alt="Some data"
						width={'auto'}
						height={'100'}
						style={{ marginTop: '1.25rem' }}
					/>
				</GridItem>
				<GridItem sm={homeMatchesSmallestWidth}>
					<Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
						Services
					</Typography>
					<Typography style={{ fontSize: 'clamp(16px, 2vw, 20px)', marginTop: '.5rem', lineHeight: 1.25 }} component="div">
						Research tools that let you learn about any family topics you could want, without the frustration of searching forever or
						 getting conflicting information. Matchmaking services that find the right help for you, whether its an IVF clinic, a holsitic
						 health practictioner, a support group online, or a coach! Planning tools that take the pain out of ART such as IVF planning, 
						 and more! 
					</Typography>
					<img
						src={data2}
						alt="Some data"
						width={'auto'}
						height={'100px'}
						style={{ marginTop: '1.25rem' }}
					/>
				</GridItem>
				<GridItem sm={homeMatchesSmallestWidth}>
					<Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
						Our Methods
					</Typography>
					<Typography style={{ fontSize: 'clamp(16px, 2vw, 20px)', marginTop: '.5rem', lineHeight: 1.25 }} component="div">
						We use a combination of technology and knowledgable expert advice to help craft all of our solutions. We develop tools to enable 
						people to make their own decisions their way, and we also provide help based on data and research! Everything in our platform is 
						curated by our team and focused on helping everyone meet their family goals.  
					</Typography>
					<img
						src={data3}
						alt="Some data"
						width={'auto'}
						height={'100px'}
						style={{ marginTop: '1.25rem' }}
					/>
				</GridItem>
			</GridContainer>
		</TextPanelContainer>
	)
}
