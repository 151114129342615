import React from "react";
import HomePage from "./HomePage/HomePage";
import TopicMainPanel from "./TopicPage/TopicMainPanel";
import { usePage } from "../../PageContext";
import Quiz from "./FamilyPlanner/Quiz/Quiz";
import Enum from "../../enums/Enum"

export default function MainPanel({
  appTheme,
  appSetTheme,
  appviewTags,
  appviewSetTags,
  appviewPosts,
  appviewSetPosts,
  appviewTopics,
  appviewReloadFeed,
  appviewSetReloadFeed,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const activePage = usePage()  // the current page

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------


  return (
    <>
      {activePage === 'home' &&
        <HomePage
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
        />
      }
      {activePage === 'familyPlanner' &&
        // <FamilyPlanner {...props} />
        <Quiz />
      }
      {activePage === Enum.Topics.Recommended &&
        <TopicMainPanel
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={0}
          topicName={Enum.Topics.Recommended}
        />
      }
      {appviewTopics[activePage] &&
        <TopicMainPanel
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={appviewTopics[activePage]}
          topicName={activePage}
        />
      }
      {activePage === Enum.Topics.Saved &&
        <TopicMainPanel
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={-1}
          topicName={Enum.Topics.Saved}
        />
      }
      {activePage === Enum.Topics.Search &&
        <TopicMainPanel
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={-2}
          topicName={Enum.Topics.Search}
        />
      }
    </>
  )
}
