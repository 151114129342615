import React, { useEffect } from "react";
import { Card, FormControl, RadioGroup, Typography } from "@mui/material";
import CheckBoxQuestion from "./CheckBoxQuestion";
import DropdownQuestion from "./DropdownQuestion";

export default function Question({ question, setResponses }) {
  const [response, setResponse] = React.useState({ idQuestion: question.id, idAnswer: null, answerText: null })

  useEffect(() => {
    if (response.idAnswer) {
      setResponses((previous) => [...previous.filter(res => 
        res.idQuestion !== response.idQuestion), response])
    }
  }, [response.idAnswer])

  return (
    <Card
      sx={{
        height: 300,
        width: 300,
        overflowX: 'auto',
      }}
    >
      <Typography 
        variant="h6" 
        fontSize="1.1rem" 
        sx={{ 
          pt: 1, 
          pb: 1, 
          pr: 2, 
          pl: 2 
        }}
      >
        {question.body}
      </Typography>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {question.answers.map((currElement, answerIndex) => (
            <>
              {question.display === "checkbox" &&
                <CheckBoxQuestion 
                  currElement={currElement}
                  answerIndex={answerIndex}
                  response={response}
                  setResponse={setResponse}
                />
              }
            </>
          ))}
          {question.display === "dropdown" &&
            <DropdownQuestion 
              question={question}
              response={response}
              setResponse={setResponse}
            />
          }
        </RadioGroup>
      </FormControl>
    </Card>
  );
}