import { Button, Typography } from '@mui/material';
import React from "react";

export default function QuizIntro({ setQuizStarted }) {
  return (
    <div
        style={{
          width: '50%',
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'Operator Mono Lig',
            color: 'white',
          }}
        >
          Take the quiz!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '1.5rem',
            color: 'white',
          }}
        >
          Quiz in beta.
        </Typography>
        <Button
          onClick={() => setQuizStarted(true)}
          sx={{
            color: 'white',
            margin: '2rem',
            fontSize: '5rem',
            fontFamily: 'Operator Mono Lig',
            border: '2px solid white',
            borderRadius: '1rem',
            "&:hover": {
              background: 'rgba(255, 255, 255, .2)',
            }
          }}
        >
          Start
        </Button>
      </div>
  )
}