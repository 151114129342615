import { Box, FormControl, MenuItem } from "@mui/material";
import { Select } from "@material-ui/core";
import { useState } from "react";
import { useTheme } from '@mui/material/styles';


export default function DropdownQuestion({ question, response, setResponse, multipart }) {
  const theme = useTheme();
  const [selection, setSelection] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelection(
      typeof value === 'string' ? value.split(',') : value,
    );
    const id = parseInt(event.target.value);
    setResponse({ ...response, idAnswer: id, answerText: null })
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl 
        fullWidth
        sx={{
          maxWidth: '80%',
          margin: 'auto'
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selection}
          onChange={handleChange}
          multiple={multipart}
          MenuProps={MenuProps}
        >
          {question.answers.map((currElement) => (
            <MenuItem 
              key={currElement.id}
              value={currElement.id}
              style={getStyles(selection, currElement.body, theme)}
            >
              {currElement.body}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}