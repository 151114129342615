import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AbcIcon from '@mui/icons-material/Abc';
import { styled, lighten, darken } from '@mui/system';

import Enum from "../../enums/Enum";
import { usePage, usePageUpdate } from "../../PageContext";
import ApiService from "../../services/ApiService";
import { transformPost } from '../../utils/PostTransform';

export default function KeywordSearch({
  appviewPosts,
  appviewSetPosts,
  appviewSetReloadFeed,
  toolbarMatchesSmallestWidth,
  toolbarMatchesMedWidth,
  toolbarSearchTerm,
  toolbarSetSearchTerm,
  toggleSearchType,
}) {

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    fontWeight: 'bold',
    color: '#1976d2',
    backgroundColor: theme.palette.mode === 'light'
      ? lighten('#42a5f5', 0.85)
      : darken('#1976d2', 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });
  const activePage = usePage();
  const updatePage = usePageUpdate();
  const [open, setOpen] = useState(false);

  const sortedPosts = [...appviewPosts.values()].sort((a, b) => -b.source.localeCompare(a.source))

  const handleOnChange = (_e, value) => {
    if (!value) return
    if (typeof (value) === "string") return

    setOpen(false);

    ApiService.findPostBySearchTerm(value.data.title)
      .then(res => res.json())
      .then(data => {
        data.posts.forEach((post) => {
          if (post) {
            appviewSetPosts((prevPosts) => (new Map(prevPosts.set(post.id, transformPost(post)))));
          }
        })
      }).then(() => {
        if (activePage !== Enum.Topics.Search) {
          updatePage(Enum.Topics.Search);
        } else {
          appviewSetReloadFeed(true);
        }
      })
      .catch(err => console.error(err))
    toolbarSetSearchTerm(value.data.title);

  }

  const handleOnKeyUp = (e) => {
    let value = e.target.value;
    if (value && value.length > 2) {
      setOpen(true);
      // TODO: consider if this is efficient
      appviewSetPosts(new Map())
      ApiService.findPostBySearchTerm(value)
        .then(res => res.json())
        .then(data => {
          data.posts.forEach((post) => {
            if (post) {
              appviewSetPosts((prevPosts) => (new Map(prevPosts.set(post.id, transformPost(post)))));
            }
            toolbarSetSearchTerm(value);
          })
        })
        .then(() => {
          if (e.key === 'Enter') {
            if (activePage !== Enum.Topics.Search) {
              updatePage(Enum.Topics.Search);
            } else {
              appviewSetReloadFeed(true);
            }
            setOpen(false);
          }
        })
        .catch(err => console.error(err))
    } else {
      setOpen(false);
    }
  }

  return (
    <Autocomplete
      freeSolo
      sx={{
        width: toolbarMatchesSmallestWidth ? 250 : toolbarMatchesMedWidth ? 400 : 600,
        pt: '.5rem',
        pb: '.5rem',
      }}
      open={open}
      options={sortedPosts}
      getOptionLabel={option => {
        // FIXME: this shouldn't be multiple type 
        if (typeof option === "string") return option
        return option.data.title
      }}
      value={toolbarSearchTerm}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(e, value) => handleOnChange(e, value)}
      onKeyUp={(e) => handleOnKeyUp(e)}
      groupBy={(option) => option.source}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ background: 'white', mt: 0, mb: 0, borderRadius: '.25rem' }}
          placeholder="Search Everywhere by Keywords"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  sx={{
                    fontSize: '1rem',
                    borderRadius: '0px'
                  }}
                  onClick={toggleSearchType}
                >
                  {!toolbarMatchesSmallestWidth &&
                    <Typography sx={{ mr: 1 }}>
                      Search by: Keywords
                    </Typography>
                  }
                  <AbcIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.data.title, inputValue, { insideWords: true })
        const parts = parse(option.data.title, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  )
}
