import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton, Tooltip } from "@mui/material";
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import RedditIcon from '../../../../resources/reddit-icon.png';
import M21FLogo from '../../../../resources/m21f-logo.png';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import getPostBody, { savePost, removePost } from './PostUtil';
import LoginDialog from "../../../Dialog/LoginDialog";
import addTooltips from "./PostUtil";
import Enum from "../../../../enums/Enum";

const LogoAbove = styled.img`
  width: 2rem; 
  height: auto; 
  float: right; 
  margin-right: 1rem; 
  @media (min-width: 1000px) {
    display: none;
  }
`;
const LogoBelow = styled.img`
  width: 3rem; 
  height: auto; 
  float: right; 
  margin-right: 1rem; 
  margin-bottom: .5rem;
  @media (max-width: 1001px) {
    display: none;
  }
`;
const RedditImage = styled.img`
  display: block; 
  max-width: 500px; 
  width: 50%; 
  height: auto; 
  margin-left: auto; 
  margin-right: auto;
`;
const PostBodyTypography = {
  fontFamily: 'Roboto',
  fontSize: '1.25rem',
  textAlign: 'left'
}
const PostBodyTypographySmall = {
  fontFamily: 'Roboto',
  fontSize: '1rem',
  textAlign: 'left'
}
const PostTitleTypography = {
  marginBottom: '1rem',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Roboto'
}
const PostCardActionsContainer = {
  float: 'left'
}
const theme = createTheme()
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
};
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
};

export default function RedditPost({
  contentMatchesSmallestWidth,
  post,
  color,
}) {
  const internalPost = ['Modern21Family', 'MythicalWilloWisp'].includes(post.data.author)
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const [saved, setSaved] = useState(false)

  const PostCardStyle = {
    marginBottom: '.1rem',
    marginTop: '1.5rem',
    width: contentMatchesSmallestWidth ? '100%' : 'calc(100% - 6rem)',
    paddingLeft: contentMatchesSmallestWidth ? 'initial' : '3rem',
    paddingRight: contentMatchesSmallestWidth ? 'initial' : '3rem',
    boxShadow: internalPost ? '1px 1px 20px 0px ' + color + ', 0px 0px 3px 0px ' + color : 'auto',
    transition: internalPost ? 'box-shadow 300ms cubic-bezier(1, 1, 1, 1) 0ms' : 'auto',
    position: 'relative',
    zIndex: '10',
    wordWrap: 'break-word'
  }


  return (
    <>
      <Card style={PostCardStyle}>
        <CardContent>
          <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="div" style={{ textAlign: 'left', marginBottom: '1rem' }}>
                {saved ?
                  <Tooltip title="Remove from Saved" placement="top">
                    <Button onClick={() => removePost(post, setSaved)}>
                      <BookmarkAddedIcon style={{ color: Enum.WebsiteColors.Green }} />
                    </Button>
                  </Tooltip>
                  :
                  <Tooltip title="Save Post" placement="top">
                    <Button onClick={() => savePost(post, setShowLoginDialog, setSaved)}>
                      <FileDownloadIcon style={{ color: Enum.WebsiteColors.Maroon }} />
                    </Button>
                  </Tooltip>
                }
                Topics {'>'} {post.data.subreddit}
              </Typography>
              <Typography variant="h6" component="div" style={{ textAlign: 'right', marginBottom: '1rem' }}>
                {post.data.time}
              </Typography>
            </Box>
            <Typography variant="h5" component="div" style={PostTitleTypography}>
              {post.data.title}
            </Typography>
            <Typography
              component={'span'}
              variant="body2"
              style={contentMatchesSmallestWidth ? PostBodyTypographySmall : PostBodyTypography}
            >
              <div dangerouslySetInnerHTML={{ __html: addTooltips(post.data.body) }} />
            </Typography>
            {post.data.url !== null && !post.data.url.toLowerCase().startsWith(
              'https://www.reddit.com/r/' + post.data.subreddit.toLowerCase()) &&
              <>
                {post.data.url.toLowerCase().endsWith('.jpg') ||
                  post.data.url.toLowerCase().endsWith('.png') ||
                  post.data.url.toLowerCase().endsWith('.gif') ?
                  <RedditImage src={post.data.redditUrl} />
                  :
                  <a href={post.data.url} target="_blank" style={PostBodyTypography}>
                    {post.data.url}
                  </a>
                }
              </>
            }
          </ThemeProvider>
        </CardContent>
        <div style={{ display: 'inline' }}>
          {internalPost ?
            <LogoAbove
              src={M21FLogo}
              alt="Modern21Family Logo"
            />
            :
            <LogoAbove
              src={RedditIcon}
              alt="Modern 21 Family Logo"
            />
          }
          <CardActions style={PostCardActionsContainer}>
            <ArrowUpwardIcon sx={{ p: '5px', color: 'grey' }} />
            {post.data.upvotes}
            <ArrowDownwardIcon sx={{ p: '5px', color: 'grey' }} />
            <div style={{ marginRight: '10px', fontFamily: 'Roboto' }}>
              {post.data.downvotes}
            </div>
            {post.data.url && post.data.url.toLowerCase().startsWith(
              'https://www.reddit.com/r/' + post.data.subreddit.toLowerCase()) &&
              <Button size="small" target={"_blank"} style={{ color: color }} href={post.data.url}>View Full Post</Button>
            }
          </CardActions>
          {internalPost ?
            <LogoBelow
              src={M21FLogo}
              alt="Modern21Family Logo"
            />
            :
            <LogoBelow
              src={RedditIcon}
              alt="Reddit Logo"
            />
          }
        </div>
      </Card>
      {showLoginDialog && <LoginDialog setShowLoginDialog={setShowLoginDialog} />}
    </>
  )
}
