import { createTheme } from '@mui/material/styles';
import styled from "styled-components";
import ApiService from "../../../services/ApiService";
import NoResultsImage from '../../../resources/noresults.png';
import { Typography } from '@mui/material';

export const marks = [
  {
    value: 0,
    label: 'Never',
  },
  {
    value: 33,
    label: 'Not for a while',
  },
  {
    value: 66,
    label: 'Soon',
  },
  {
    value: 100,
    label: 'Now!',
  },
]

export function valuetext(value) {
  return `${value}`
}

export const theme = createTheme()
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
}
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
}
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
}
export const GridContainer = styled.div(({ showFilters, fromContentPage }) => ({
  "@media (min-width: 751px)": {
    display: 'grid',
    gridTemplateRows: (showFilters && fromContentPage) ? '300px calc(100% - 300px)' : '200px calc(100% - 200px)',
    gridTemplateAreas: `'filters' 'main'`,
    width: '100%'
  }
}))
export const Main = styled.div`
  @media (max-width: 750px) {
    margin-left: auto;
  }
`

export const handleDialogClose = (openDialog, newWhenStartFamilyResponse, setOpenDialog) => {
  if (openDialog) {
    setOpenDialog(false)
  }

  ApiService.updateAccount({
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    },
    body: JSON.stringify({
      'whenStartFamilyResponse': newWhenStartFamilyResponse
    })
  })
}

export default function NoResults({ topicmainLoaded }) {
  return (
    <>
      {topicmainLoaded &&
        <div style={{ textAlign: 'center' }}>
          <img
            src={NoResultsImage}
            alt="No Results"
            style={{ width: '15%', height: 'auto', marginTop: '2.5rem' }}
          />
          <Typography variant="h5" style={{ marginTop: '2rem', fontFamily: 'Roboto' }}>
            Oops... couldn't find any results. Sorry!
          </Typography>
        </div>
      }
    </>
  )
}

