import styled from "styled-components";

export const AppContainer = styled.div`
    height: calc(100vh - 4.5rem);
    display: grid;

    @media (min-width: 1930px) {            
        grid-template-columns: 
        13% auto;
    }

    @media (max-width: 1929px) {
        grid-template-columns: 
        18% auto;
    }

    @media (max-width: 1500px) {
        grid-template-columns: 
        25% auto;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 
        100% 0;
    }
`