import Enum from "../../../../enums/Enum";
import ApiService from "../../../../services/ApiService";
import './PostUtil.css';

const keyWordsToDescriptionMap = new Map([
  ['TTC', 'Usually means "trying to conceive"'],
  ['FET', 'A frozen embryo transfer, commonly referred to as FET, \
    is the process of using a frozen embryo from a previous in vitro fertilisation cycle. \
    It\'s gaining popularity due to its safety and pregnancy success rates. \
    <a target="_blank" href="https://www.aspirefertility.com/fertility-treatment/ivf/\
    fet-frozen-embryo-transfer">More info</a>'],
  ['TSH', 'TSH stands for thyroid stimulating hormone. A TSH test is a blood \
    test that measures this hormone. TSH levels that are too high or too low may be a sign of a thyroid problem.\
    <a target="_blank" href="https://medlineplus.gov/lab-tests/tsh-thyroid-stimulating-hormone-test/#:~:text=TSH\
    %20stands%20for%20thyroid%20stimulating,how%20your%20body%20uses%20energy.">More info</a>'],
  ['IVF', 'In vitro fertilisation (IVF) is one of several techniques available to help people with \
    fertility problems have a baby. During IVF, an egg is removed from the woman\'s ovaries and \
    fertilised with sperm in a laboratory. The fertilised egg, called an embryo, is then returned \
    to the woman\'s womb to grow and develop.\
    <a target="_blank" href="https://www.nhs.uk/conditions/ivf/#:~:text=In%20vitro%20fertilisation%20\
    (IVF)%20is,womb%20to%20grow%20and%20develop.">More info</a>'],
  ['PIO', 'Progesterone helps support implantation and the IVF process blunts \
    your body\'s natural ability to make progesterone. \
    <a target="_blank" href="https://fertility.wustl.edu/progesterone-and-ivf/">More info</a>'],
  ['EMLA', 'EMLA™ cream is an effective and well-tolerated topical \
    anaesthetic agent for needle insertion and minor dermatological \
    procedures including laser therapy of birthmarks \
    <a target="_blank" href="https://www.medsafe.govt.nz/profs/PUarticles/EMLA.htm">More info</a>'],
  ['T/C', 'Typical ovulation tests have 2 lines: the test line (T), and the control line (C). \
    The T/C ratio simply compares the color intensity of the T line to the color intensity of the C line. \
    <a target="_blank" href="https://premom.com/blogs/what-is-premom-and-why-premom/what-is-a-t-c-ratio#:~:\
    text=The%20T%2FC%20ratio%20simply,indicating%20your%20test%20is%20positive.">More info</a>'],
  ['TCOYF', 'Refers to "Taking Charge of Your Fertility": \
    <a target="_blank" href="https://www.tcoyf.com/">tcoyf.com</a>'],
  ['CoQ10', 'Coenzyme Q10 (CoQ10) is an antioxidant that your body produces naturally. \
    Levels of CoQ10 in your body decrease as you age. CoQ10 levels have also been \
    found to be lower in people with certain conditions, such as heart disease, and \
    in those who take cholesterol-lowering drugs called statins. \
    <a target="_blank" href="https://www.mayoclinic.org/drugs-supplements-coenzyme-q10/art-20362602">More info</a>'],
  ['OB', 'Obstetrical nursing, also called perinatal nursing, is a nursing specialty that works with patients who are \
    attempting to become pregnant. \
    <a target="_blank" href="https://nurse.org/resources/how-to-become-OB-nurse/">More info</a>'],
  ['NFP', 'Natural Family Planning (NFP) relies on the ability to track ovulation in order to prevent pregnancy. \
    These methods predict fertile and unfertile days to identify when to avoid unprotected sex and are only used by \
    a small fraction of women. \
    <a target="_blank" href="https://www.nhs.uk/conditions/contraception/natural-family-planning/">More info</a>'],
  ['FAM', 'Fertility awareness methods (FAMs) are ways to track your ovulation so you can prevent pregnancy. \
    <a target="_blank" href="https://www.plannedparenthood.org/learn/birth-control/fertility-awareness">More info</a>']
])

export default function addTooltips(body) {
  // for (const [key, value] of keyWordsToDescriptionMap.entries()) {
  //   body = body.replaceAll(key, '<span class="tooltip">' + key + '<span class="tooltiptext">' + value + '</span></span>')
  // }
  return body
}

export function getVideoDescription(description) {
  return handleStandardLinks(description)
}

function handleStandardLinks(str) {
  var httpRegexG = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g
  for (const match of str.matchAll(httpRegexG)) {
    str = str.replaceAll(match, "<a target=\"_blank\" href=" + match + ">" + match + "</a>")
  }
  return str
}

export const isAuthenticated = () => {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  return ApiService.isUserAuthenticated(options)
}

export const savePost = (post, setShowLoginDialog, setSaved) => {
  if (localStorage.getItem("token") == null) {
    setShowLoginDialog(true)
    return
  };
  const postId = post.id;
  isAuthenticated()
    .then((res) => res.json())
    .then((data) => {
      if (data.status === 'Success') {
        let options = {
          method: 'POST',
          body: JSON.stringify({ postId }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + localStorage.getItem('token')
          },
        }
        ApiService.savePost(options)
          .then((headers) => {
            if (headers.status === 201 || headers.status === 200) setSaved(true)
          })
      } else {
        setShowLoginDialog(true)
      }
    })
    .catch((err) => console.error(err))
}

export const removePost = (post, setSaved) => {
  if (localStorage.getItem("token") == null) {
    return
  };
  const postId = post.id;
  let options = {
    method: 'DELETE',
    body: JSON.stringify({ postId }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    },
  }
  ApiService.removePost(options)
    .then((headers) => {
      if (headers.status === 200) setSaved(false)
    })
}

