import React, { useState } from "react";
import styled from "styled-components";
import QuizIntro from './QuizIntro';
import QuizStepper from "./QuizStepper";
import Enum from "../../../../enums/Enum";
const QuizContainer = styled.div(({ quizStarted, bgColor }) => ({
  border: '2px solid lightgrey',
  borderRadius: '.5rem',
  width: '75%',
  margin: '2rem auto',
  padding: '5rem 0',
  textAlign: 'center',
  fontFamily: 'Operator Mono Lig',
  background: bgColor,
  backgroundImage: quizStarted ? 'initial' : 'linear-gradient(to bottom right, rgb(36, 107, 124), rgb(184, 51, 106))',
}));
export default function Quiz() {
  const [quizStarted, setQuizStarted] = useState(false)
  
  return (
    <QuizContainer
      quizStarted={quizStarted}
      bgColor={Enum.WebsiteColors.Green}
    >
      {quizStarted ?
        <>
          <QuizStepper
            setQuizStarted={setQuizStarted}
          />
        </>
      :
        <QuizIntro
          setQuizStarted={setQuizStarted}
        />
      }
    </QuizContainer>
  )
}