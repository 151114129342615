import React, { useState } from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { Button, Grid, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from './Questions/Snackbar';
import Enum from '../../enums/Enum';

const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))
const LabelTypography = {
  fontWeight: '600',
}
const LabelTypographyWithMargin = {
  fontWeight: '600',
  marginTop: '1.5rem',
}

export default function AccountPage({
  settingsSidebarOpen,
  settingsSnackbarOpen,
  settingsSetSnackbarOpen,
  settingsUserInfo,
  settingsSetUserInfo,
  settingsShowSuccessMessage,
  settingsmainMatchesSmallestWidth,
  settingsmainMatchesMedWidth,
  handleToggleSidebar,
  saveAccountInfo,
  color,
}) {
  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [accordionExpanded, setAccordionExpanded] = useState(false)

  const MainPanelContainer = {
    width: '100%',
    padding: settingsmainMatchesMedWidth ? '1.5rem 1rem' : '1.5rem 4rem',
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return

    settingsSetSnackbarOpen(false)
  }

  return (
    <div style={MainPanelContainer}>
      <div style={{ width: '100%', padding: settingsmainMatchesMedWidth ? 0 : 'initial' }}>
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginBottom: '1rem' }}
          >
            {settingsmainMatchesSmallestWidth && !settingsSidebarOpen &&
              <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
                <Grid item xs={1}>
                  <Button variant="text" sx={{ color: 'black', marginRight: '.5rem' }}>
                    <ArrowBackIcon />
                  </Button>
                </Grid>
              </BackArrowContainer>
            }
            <Grid item xs={1}>
              <Typography
                variant={settingsmainMatchesSmallestWidth ? "h5" : "h4"}
                component="div"
                sx={{ fontWeight: '900', paddingLeft: '0px' }}
              >
                Account
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" component="div">
            Profile
          </Typography>
          <Typography variant="subtitle2" component="div" sx={{ color: 'grey', fontWeight: 'normal' }}>
            The below information will display publicly, so be mindful of what you would like to share.
          </Typography>
          <Grid
            container
            direction={settingsmainMatchesSmallestWidth ? 'column' : 'row'}
            alignItems={settingsmainMatchesSmallestWidth ? 'left' : 'center'}
            sx={{
              marginTop: '1.5rem'
            }}
          >
            <Grid item xs={5} style={{ marginBottom: settingsmainMatchesSmallestWidth ? '1rem' : 0 }}>
              <Typography variant="h7" component="div" sx={LabelTypography}>
                Username
              </Typography>
              <TextField
                id="username"
                variant="outlined"
                hiddenLabel
                size="small"
                value={settingsUserInfo.username}
                onChange={(event) => {
                  settingsSetUserInfo(current => {
                    return { ...current, username: event.target.value }
                  })
                }}
                sx={{ width: 'calc(100% - 1rem)', marginTop: '.25rem' }}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h7" component="div" sx={LabelTypography}>
                Email
              </Typography>
              <TextField
                id="email"
                variant="outlined"
                hiddenLabel
                size="small"
                value={settingsUserInfo.email}
                onChange={(event) => {
                  settingsSetUserInfo(current => {
                    return { ...current, email: event.target.value }
                  })
                }}
                sx={{ width: 'calc(100% - 1rem)', marginTop: '.25rem' }}
              />
            </Grid>
          </Grid>
          <Typography variant="h7" component="div" sx={LabelTypographyWithMargin}>
            About
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            value={settingsUserInfo.about}
            onChange={(event) => {
              settingsSetUserInfo(current => {
                return { ...current, about: event.target.value }
              })
            }}
            sx={{ width: settingsmainMatchesSmallestWidth ? '100%' : 'calc(83.33% - 1rem)', marginTop: '.25rem' }}
          />
        </>
        <Button onClick={() => setAccordionExpanded(!accordionExpanded)}
          style={{
            width: settingsmainMatchesSmallestWidth ? '100%' : 'calc(83.33% - 1rem)', marginTop: '2rem',
            background: color, color: 'white', padding: '.3rem',
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            onClick={() => saveAccountInfo()}
            sx={{ "&:hover": { background: color } }}
          >
            <Grid item xs={12}>
              Save
            </Grid>
          </Grid>
        </Button>
        <Snackbar
          snackbarOpen={settingsSnackbarOpen}
          showSuccessMessage={settingsShowSuccessMessage}
          autoHideDuration={1000}
          handleCloseSnackbar={handleCloseSnackbar}
        />
      </div>
    </div>
  )
}
