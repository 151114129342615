const prefix = process.env.REACT_APP_API_ENDPOINT_PREFIX || 'http://127.0.0.1:8000'

function applyBitmask(
  includeReddit,
  includeEmail,
  includeYoutube,
) {
  let bitmask = 0;

  bitmask |= (includeReddit ? 1 : 0) << 0; // Reddit bit 0
  bitmask |= (includeEmail ? 1 : 0) << 1; // Email bit 1
  bitmask |= (includeYoutube ? 1 : 0) << 2; // Youtube bit 2

  return bitmask.toString(16);
}

function getPostsQueryExtension(
  page,
  idTopic,
  includeReddit,
  includeEmail,
  includeYoutube,
) {
  let queryExtension = '';
  queryExtension += `page=${page}`;
  queryExtension += `&idTopic=${idTopic}`;
  let bitmask = applyBitmask(
    includeReddit,
    includeEmail,
    includeYoutube,
  )
  queryExtension += `&source=${bitmask}`;
  return queryExtension;
}

const getAllPosts = (
  page,
  idTopic,
  includeReddit,
  includeEmail,
  includeYoutube,
) => {
  let getPostApiCall = '/posts/get_all_posts'
  let extension = getPostsQueryExtension(page, idTopic, includeReddit, includeEmail, includeYoutube);
  let query = `${getPostApiCall}?${extension}`
  return fetch(prefix + query)
}

const getInternalPosts = (options) => fetch(prefix + '/posts/get_internal_posts/', options)
const createInternalPost = (options) => fetch(prefix + '/posts/create_internal_post/', options)
const updateInternalPost = (options) => fetch(prefix + '/posts/update_internal_post/', options)
const deleteInternalPost = (options) => fetch(prefix + '/posts/delete_internal_post/', options)
const login = (options) => fetch(prefix + '/account/login/', options)
const register = (options) => fetch(prefix + '/account/register/', options)
const getAccountInfo = (options) => fetch(prefix + '/account/info/', options)
const updateDatasources = (options) => fetch(prefix + '/admin_tools/update/', options)
const updateAccount = (options) => fetch(prefix + '/account/update/', options)
const getIsAdminUser = (options) => fetch(prefix + '/account/get_is_admin_user/', options)
const getAdminData = (options) => fetch(prefix + '/account/admin_data/', options)
const addTokens = (options) => fetch(prefix + '/account/add_tokens/', options)
const getTokenCount = (options) => fetch(prefix + '/account/get_token_count/', options)
const getSavedPosts = (options) => fetch(prefix + '/posts/get_saved_posts/', options)
const savePost = (options) => fetch(prefix + '/posts/save_post/', options)
const removePost = (options) => fetch(prefix + '/posts/remove_saved_post/', options)
const isUserAuthenticated = (options) => fetch(prefix + '/account/is_authenticated/', options)
const findPostBySearchTerm = (searchTerm) => fetch(prefix + '/posts/search_by_term?search_term=' + searchTerm)
const openPaymentWindow = (options) => fetch(prefix + '/payment/process/', options)
const getProfileQuestions = (options) => fetch(prefix + '/admin_portal/get_prof_questions/', options)
const saveSurveyResponse = (options) => fetch(prefix + '/survey/save_survey_response/', options)
const getSurveyQuestions = (options) => fetch(prefix + '/survey/get_survey_questions/', options)
const getTopicSubs = (options) => fetch(prefix + '/topic/get_subs_for_account/', options)
const getAllTopics = (options) => fetch(prefix + '/topic/get_all_topics/', options)
const subToTopic = (options) => fetch(prefix + '/topic/create_subscription/', options)
const getSubredditsForTopic = (options, topic) => fetch(prefix + '/topic/get_subreddits_for_topic?topic=' + topic.replaceAll("&", "%26"), options)
const linkSubredditsToTopic = (options) => fetch(prefix + '/topic/link_subreddits_to_topic/', options)
const deleteSubredditLink = (options) => fetch(prefix + '/topic/remove_subreddit_link/', options)
const getTags = (options) => fetch(prefix + '/posts/get_tags/', options)
const getAllPostsWithTag = (tag) => fetch(prefix + '/posts/get_posts_with_tag?tag=' + tag)

export default {
  getAllPosts,
  getAllPostsWithTag,
  getInternalPosts,
  createInternalPost,
  updateInternalPost,
  deleteInternalPost,
  login,
  register,
  getAccountInfo,
  updateDatasources,
  updateAccount,
  getIsAdminUser,
  getAdminData,
  addTokens,
  getTokenCount,
  getSavedPosts,
  savePost,
  removePost,
  isUserAuthenticated,
  findPostBySearchTerm,
  openPaymentWindow,
  getProfileQuestions,
  getSurveyQuestions,
  saveSurveyResponse,
  getTopicSubs,
  getAllTopics,
  subToTopic,
  getSubredditsForTopic,
  linkSubredditsToTopic,
  deleteSubredditLink,
  getTags,
}
