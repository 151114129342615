import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FamilyPlannerContainer } from "../styles/FamilyPlannerContainer.styled";
import { usePage, usePageUpdate } from "../../PageContext";
import ApiService from "../../services/ApiService";
import LoginDialog from "../Dialog/LoginDialog";

export const FamilyPlannerPhotoContainer = styled.img(({ hasBorder, width, smWidth, sm }) => ({
  width: sm ? smWidth : width,
  height: 'auto',
  border: hasBorder ? '2px solid #B0B0B0' : 'initial',
  cursor: 'pointer',
}))
export const LockPhotoContainer = styled.img`
  width: 65%;
  height: auto;
  cursor: pointer;
  background: rgba(169, 169, 169, .6);
  @media (max-width: 1000px) {
    width: 45%;
  }
`

export default function FamilyPlannerSidePanel({ isUserAuthenticated }) {
  const activePage = usePage()
  const updatePage = usePageUpdate()
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const [matchesMedWidth, setMatchesMedWidth] = useState(window.matchMedia("(max-width: 2275px)").matches)
  const [matchesSmallWidth, setMatchesSmallWidth] = useState(window.matchMedia("(max-width: 1929px)").matches)
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(window.matchMedia("(max-width: 750px)").matches)
  const [matchesMedHeight, setMatchesMedHeight] = useState(window.matchMedia("(max-height: 1200px)").matches)
  const [matchesSmallHeight, setMatchesSmallHeight] = useState(window.matchMedia("(max-height: 1000px)").matches)
  const [matchesSmallestHeight, setMatchesSmallestHeight] = useState(window.matchMedia("(max-height: 800px)").matches)

  let featureDark = true

  useEffect(() => {
    window.matchMedia("(max-width: 750px)")
      .addEventListener('change', e => setMatchesSmallestWidth(e.matches))
    window.matchMedia("(max-width: 1929px)")
      .addEventListener('change', e => setMatchesSmallWidth(e.matches))
    window.matchMedia("(max-width: 2275px)")
      .addEventListener('change', e => setMatchesMedWidth(e.matches))
    window.matchMedia("(max-height: 800px)")
      .addEventListener('change', e => setMatchesSmallestHeight(e.matches))
    window.matchMedia("(max-height: 1000px)")
      .addEventListener('change', e => setMatchesSmallHeight(e.matches))
    window.matchMedia("(max-height: 1200px)")
      .addEventListener('change', e => setMatchesMedHeight(e.matches))
  }, [])

  const onFamilyPlannerClicked = () => {
    if (featureDark) {
      setShowLoginDialog(true)
      return
    }
    if (isUserAuthenticated) {
      updatePage('familyPlanner')
    } else {
      setShowLoginDialog(true)
    }
  }

  return (
    <>
      <FamilyPlannerContainer>
        {featureDark ?
          <FamilyPlannerPhotoContainer 
            src={require("../../resources/planner_icon.png")} 
            onClick={() => onFamilyPlannerClicked()} 
            hasBorder={false}
            width={'40%'}
            smWidth={'45%'}
            sm={matchesSmallWidth}
          />
          :
          <FamilyPlannerPhotoContainer 
            src={require("../../resources/planner_icon.png")} 
            onClick={() => onFamilyPlannerClicked()} 
            hasBorder={true}
            width={'50%'}
            smWidth={'55%'}
            sm={matchesSmallWidth}
          />
        }
      </FamilyPlannerContainer>
      {showLoginDialog && <LoginDialog setShowLoginDialog={setShowLoginDialog} featureDark={featureDark} />}
    </>
  )
}
