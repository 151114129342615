import React from "react";
import styled from "styled-components";

const ToolbarContainer = styled.div`
	width: 100%; 
	height: 70px; 
	background-color: rgba(211, 211, 211, 0);
	position: absolute;
	left: 0px;
  top: 0px;
`;

export default function HomeToolbar() {
	return (
		<ToolbarContainer>
			<img
				src={require("../../resources/m21f-logo-white.png")}
				alt="Modern 21 Family Logo"
				style={{ width: '65px', height: 'auto', marginLeft: '1rem', marginTop: '.5rem' }}
			/>
		</ToolbarContainer>
	)
}