import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { usePage, usePageUpdate } from "../../PageContext.js";
import { Navigate } from "react-router-dom";
import ApiService from "../../services/ApiService.js";
import Enum from "../../enums/Enum.js";
import TagSearch from "./TagSearch.js";
import KeywordSearch from "./KeywordSearch.js";
import LoginDialog from "../Dialog/LoginDialog.js";

const DividerStyle = {
  bgcolor: 'white',
  width: '1px',
}

export default function Toolbar({
  appTheme,
  appviewSidebarOpen,
  appviewPosts,
  appviewSetPosts,
  appviewTags,
  appviewSetTags,
  appviewTopics,
  appviewSetReloadFeed,
  handleMenuClick,
  callingFrom,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [browseSelected, setBrowseSelected] = useState(false)
  const [planSelected, setPlanSelected] = useState(false)
  const [profileSelected, setProfileSelected] = useState(false)
  const [adminSelected, setAdminSelected] = useState(false)
  const [navigateHome, setNavigateHome] = useState(false)
  const [adminUser, setAdminUser] = useState(false)
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const open = Boolean(anchorEl)
  let bgColor = appTheme === 'Default' ? Enum.WebsiteColors.Green : appTheme === 'Maroon' ? Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet
  const updatePage = usePageUpdate()
  const activePage = usePage()
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(window.matchMedia("(max-width: 750px)").matches)
  const [matchesMedWidth, setMatchesMedWidth] = useState(window.matchMedia("(max-width: 1000px)").matches)
  const [searchTerm, setSearchTerm] = useState("")
  const SearchTypes = {
    Keyword: 'Keyword',
    Tag: 'Tag',
  }
  const [searchBy, setSearchBy] = useState(SearchTypes.Keyword)
  const authToken = localStorage.getItem('token')
  const username = localStorage.getItem('username')
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + authToken
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const SearchEverywhereStyle = {
    width: matchesSmallestWidth ? '70%' : '85%',
    display: 'flex',
    justifyContent: 'center',
  }

  const logout = () => {
    setAnchorEl(null)
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('questionAnswered')
    window.location.reload()
  }

  const handleBrowseClicked = () => {
    if (callingFrom === 'Settings') {
      setNavigateHome(true)
    }
    updatePage('home')
    setBrowseSelected(true)
    setPlanSelected(false)
    setProfileSelected(false)
    setAdminSelected(false)
  }

  const handlePlanClicked = () => {
    setShowLoginDialog(true)
    // if (callingFrom === 'Settings') {
    //   setNavigateHome(true)
    // }
    // updatePage('familyPlanner')
    // setBrowseSelected(false)
    // setPlanSelected(true)
    // setProfileSelected(false)
    // setAdminSelected(false)
  }

  const handleProfileClicked = () => {
    setBrowseSelected(false)
    setPlanSelected(false)
    setProfileSelected(true)
    setAdminSelected(false)
  }

  const handleAdminClicked = () => {
    setBrowseSelected(false)
    setPlanSelected(false)
    setProfileSelected(false)
    setAdminSelected(true)
  }

  useEffect(() => {
    if (callingFrom === 'AppView') {
      setBrowseSelected(true)
    } else {
      setProfileSelected(true)
    }
    if (localStorage.getItem("token") != null) {
      ApiService.getIsAdminUser(options)
        .then((res) => res.json())
        .then((data) => {
          setAdminUser(data)
        })
        .catch((err) => console.error(err))
    }

    window.matchMedia("(max-width: 750px)")
      .addEventListener('change', e => setMatchesSmallestWidth(e.matches))
    window.matchMedia("(max-width: 1000px)")
      .addEventListener('change', e => setMatchesMedWidth(e.matches))
  }, [])

  useEffect(() => {
    setSearchTerm("")
  }, [activePage]);

  const toggleSearchType = () => {
    if (searchBy === SearchTypes.Keyword) {
      setSearchBy(SearchTypes.Tag)
    } else {
      setSearchBy(SearchTypes.Keyword)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="static">
        <MuiToolbar sx={{ background: bgColor }}>
          {callingFrom !== 'Settings' && !appviewSidebarOpen &&
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 2,
                "@media (min-width: 1001px)": {
                  display: 'none'
                }
              }}
              onClick={handleMenuClick}
            >
              <HomeIcon />
            </IconButton>
          }
          {username === null || username === undefined || username === 'undefined' ?
            <>
              {(!matchesMedWidth || appviewSidebarOpen) && <>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1
                  }}
                >
                  Modern 21 Family
                </Typography>
              </>
              }
            </>
            :
            <>
              {(!matchesMedWidth || appviewSidebarOpen) && <>
                {callingFrom === 'AppView' ?
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Hello, {username}!
                  </Typography>
                  :
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1
                    }}
                  >
                    Settings
                  </Typography>
                }
              </>}
            </>
          }
          {callingFrom === 'AppView' && !(matchesMedWidth && appviewSidebarOpen) && <>
            <div style={SearchEverywhereStyle}>
              {searchBy === SearchTypes.Keyword ?
                <KeywordSearch
                  appviewPosts={appviewPosts}
                  appviewSetPosts={appviewSetPosts}
                  appviewSetReloadFeed={appviewSetReloadFeed}
                  toolbarMatchesSmallestWidth={matchesSmallestWidth}
                  toolbarMatchesMedWidth={matchesMedWidth}
                  toolbarSearchTerm={searchTerm}
                  toolbarSetSearchTerm={setSearchTerm}
                  toggleSearchType={toggleSearchType}
                />
                :
                <TagSearch
                  appviewPosts={appviewPosts}
                  appviewSetPosts={appviewSetPosts}
                  appviewSetReloadFeed={appviewSetReloadFeed}
                  toolbarMatchesSmallestWidth={matchesSmallestWidth}
                  toolbarMatchesMedWidth={matchesMedWidth}
                  toolbarSearchTerm={searchTerm}
                  toolbarSetSearchTerm={setSearchTerm}
                  toggleSearchType={toggleSearchType}
                />
              }
            </div>
          </>}
          {authToken === null ?
            <Button color="inherit" href="/login">Login</Button>
            :
            <>
              {!matchesMedWidth && <>
                <Divider
                  sx={DividerStyle}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={() => handleBrowseClicked()}
                  color="inherit"
                  sx={{
                    borderBottom: browseSelected ? '2px solid white' : 'initial',
                    '&:hover': { background: 'rgba(255, 255, 255, .2)' },
                    padding: '1rem 1.5rem',
                  }}
                >
                  Research 
                </Button>
                <Divider 
                  sx={DividerStyle} 
                  orientation="vertical" 
                  variant="middle" 
                  flexItem 
                />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={() => handlePlanClicked()}
                  color="inherit"
                  sx={{ 
                    borderBottom: planSelected ? '2px solid white' : 'initial', 
                    '&:hover': { background: 'rgba(255, 255, 255, .2)' }, 
                    padding: '1rem 1.5rem', 
                  }}
                >
                  Plan
                </Button>
                <Divider
                  sx={DividerStyle}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Button
                  id="basic-button"
                  onClick={() => handleProfileClicked()}
                  color="inherit"
                  href="/settings"
                  sx={{
                    borderBottom: profileSelected ? '2px solid white' : 'initial',
                    '&:hover': { background: 'rgba(255, 255, 255, .2)' },
                    padding: '1rem 1.5rem',
                  }}
                >
                  Profile
                </Button>
                <Divider
                  sx={DividerStyle}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                {adminUser && <>
                  <Button
                    id="basic-button"
                    onClick={() => handleAdminClicked()}
                    color="inherit"
                    href="/admin"
                    sx={{
                      borderBottom: adminSelected ? '2px solid white' : 'initial',
                      '&:hover': { background: 'rgba(255, 255, 255, .2)' },
                      padding: '1rem 1.5rem',
                    }}
                  >
                    Admin
                  </Button>
                  <Divider
                    sx={DividerStyle}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                </>}
              </>}
              <Button
                id="basic-button"
                onClick={handleClick}
                color="inherit"
                sx={{ ml: 1 }}
              >
                <SettingsIcon color="inherit" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {callingFrom === 'AppView' ?
                  <MenuItem component={Link} to={'/settings'}>Settings</MenuItem>
                  :
                  <MenuItem component={Link} to={'/'}>Home</MenuItem>
                }
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </>
          }
        </MuiToolbar>
      </AppBar>
      {navigateHome && <Navigate to='/' />}
      {showLoginDialog && 
        <LoginDialog 
          setShowLoginDialog={setShowLoginDialog} 
          featureDark={true}
        />
      }
    </Box>
  )
}
