import { useState, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { Button, Divider, FormHelperText, Grid, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CoinsImage from './../../resources/coins.png';
import OneCoin from './../../resources/one-coin.png';
import TwoCoins from './../../resources/two-coins.png';
import ThreeCoins from './../../resources/three-coins.png';
import FourCoins from './../../resources/four-coins.png';
import ConfettiExplosion from 'react-confetti-explosion';
import ApiService from '../../services/ApiService';
import CountUp from 'react-countup';
import PayPal from './PayPal';
import Enum from '../../enums/Enum';

const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))
const MainPanelContainer = styled.div`
  width: 100%;
  padding: 1.5rem 4rem;
  @media (max-width: 1450px) {
    padding: 1.5rem 1rem;
  }
`;
const CurrentCountStyle = {
  width: 'fit-content',
  height: '75px',
  border: '2px solid lightgrey',
  borderRadius: '.5rem',
  display: 'flex',
  justifyContent: 'center',
  boxShadow: '10px 10px 10px -1px rgb(36, 107, 124 / 20%), 0px 1px 1px 0px rgb(36, 107, 124 / 14%), 0px 1px 3px 0px rgb(36, 107, 124 / 12%)',
  alignItems: 'center',
  padding: '0 1rem',
  marginBottom: '1rem',
}

export default function BillingPage({
  settingsSidebarOpen,
  handleToggleSidebar,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [originalTokenCount, setOriginalTokenCount] = useState(0)
  const [nTokensToPurchase, setNTokensToPurchase] = useState(1)
  const [maxTokensHit, setMaxTokensHit] = useState(false)
  const [isExploding, setIsExploding] = useState(false)
  const [currentImage, setCurrentImage] = useState(CoinsImage)
  const [purchaseValue, setPurchaseValue] = useState('1.99')
  const [checkout, setCheckout] = useState(false)
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )

  const maxTokens = 10
  const coinPrice = 1.99
  Object.freeze(coinPrice)
  let subtotal = (nTokensToPurchase * coinPrice).toFixed(2)
  let purchasePrice = (subtotal * 1).toFixed(2)

  // -------------------------------------------------------------------------
  // PROPS
  // -------------------------------------------------------------------------
  useEffect(() => {
    getTokenCount()
  }, [checkout])

  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
    getTokenCount()
  }, [])

  const getTokenCount = () => {
    ApiService.getTokenCount({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setOriginalTokenCount(data.Count)
        }
      })
      .catch((err) => console.error(err))
  }

  const initialOptions = {
    //AYXXeGOkF6fauVGt4q9iEAI_TmxYmLhR-9DCi7q_j1K7Q2N1Xa7TtAGsv1D6jtpzC1VBJNrHiJCr4tuY
    "client-id": "AYXXeGOkF6fauVGt4q9iEAI_TmxYmLhR-9DCi7q_j1K7Q2N1Xa7TtAGsv1D6jtpzC1VBJNrHiJCr4tuY&enable-funding=venmo&currency=USD",
    // currency: "USD",
    // intent: "capture",
    // "data-client-token": "abc123xyz==",
  }

  const updateCurrentImage = (num) => {
    if (num === 1) setCurrentImage(OneCoin)
    if (num === 2) setCurrentImage(TwoCoins)
    if (num === 3) setCurrentImage(ThreeCoins)
    if (num === 4) setCurrentImage(FourCoins)
  }

  const updateTokenCount = (e) => {
    if (e.target.value && isNaN(e.target.value)) return
    const num = e.target.value === '' ? 0 : e.target.value
    if (num > maxTokens) {
      setMaxTokensHit(true)
    } else {
      setNTokensToPurchase(parseFloat(num))
      setPurchaseValue((nTokensToPurchase * coinPrice).toFixed(2))
      updateCurrentImage(parseInt(num))
      setMaxTokensHit(false)
      setIsExploding(false)
    }
  }

  const incrementToken = () => {
    if (nTokensToPurchase >= maxTokens) {
      setMaxTokensHit(true)
    } else {
      setNTokensToPurchase((previous) => previous + 1)
      setPurchaseValue((nTokensToPurchase * coinPrice).toFixed(2))
      updateCurrentImage(nTokensToPurchase)
      setMaxTokensHit(false)
      setIsExploding(true)
    }
  }

  const decrementToken = () => {
    if (nTokensToPurchase > 0) {
      setNTokensToPurchase((previous) => previous - 1)
      setPurchaseValue((parseFloat(nTokensToPurchase.toFixed(2)) * parseFloat(coinPrice.toFixed(2))))
      updateCurrentImage(nTokensToPurchase)
      setMaxTokensHit(false)
      setIsExploding(false)
    }
  }

  useEffect(() => {
    if (isExploding) {
      setTimeout(function() {
        setIsExploding(false)
      }, 3000)
    }
  }, [isExploding])

  return (
    <MainPanelContainer>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '1rem' }}
      >
        {matchesSmallestWidth && !settingsSidebarOpen &&
          <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
            <Grid item xs={1}>
              <Button
                variant="text"
                sx={{ color: 'black', marginRight: '.5rem' }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </BackArrowContainer>
        }
        <Grid item xs={1}>
          <Typography
            variant={matchesSmallestWidth ? "h5" : "h4"}
            component="div"
            sx={{ fontWeight: '900', paddingLeft: '0px' }}
          >
            Payments
          </Typography>
        </Grid>
      </Grid>
      {!checkout && <>
        <div style={CurrentCountStyle}>
          <Typography variant="h6">Current Total:
            <CountUp
              style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
              end={originalTokenCount}
              duration={.25}
            />
            coins
          </Typography>
        </div>
        <div style={{ width: 'fit-content', marginBottom: '3rem' }}>
          <Typography variant="h6">How many tokens would you like to purchase?</Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>
            <Button size="small" sx={{ ml: 0 }} onClick={decrementToken}><RemoveIcon sx={{ color: 'black' }} /></Button>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px', height: '50px' }}>
              <TextField value={nTokensToPurchase} onChange={(e) => updateTokenCount(e)}></TextField>
            </div>
            <Button size="small" sx={{ ml: 0 }} onClick={incrementToken}>
              <AddIcon sx={{ color: 'black' }} />
              {isExploding && <ConfettiExplosion force={.4} duration={1500} particleCount={60} />}
            </Button>
          </div>
          {maxTokensHit && <FormHelperText error>You can only purchase 10 tokens at a time.</FormHelperText>}
          <div style={{ background: '#f2f2f2', border: 'thin solid grey', borderRadius: '.5rem', width: 'fit-content', marginTop: matchesSmallestWidth ? '3rem' : '6rem' }}>
            <img
              src={CoinsImage}
              alt="coins"
              width={'auto'}
              height={matchesSmallestWidth ? '100' : '150px'}
              style={{ marginTop: matchesSmallestWidth ? '-3rem' : '-5rem', float: 'right' }}
            />
            <Typography variant="h5" fontWeight={600} marginLeft={2} marginBottom={1} paddingTop={1}>Order Summary</Typography>
            <div style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
              <div style={{ display: 'flex', gap: '5rem', marginBottom: '.5rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                <Typography variant="h6" sx={{ width: matchesSmallestWidth ? '70px' : '100px' }} fontWeight={400}>Subtotal</Typography>
                <Typography variant="h6" fontWeight={400}>${subtotal}</Typography>
              </div>
              <div style={{ display: 'flex', gap: '5rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                <Typography variant="h6" sx={{ width: matchesSmallestWidth ? '70px' : '100px' }} fontWeight={400}>Sales Tax </Typography>
                <Typography variant="h6" fontWeight={400}>${(subtotal * 0).toFixed(2)}</Typography>
              </div>
              <Divider color={'grey'} sx={{ mt: 1 }} />
              <div style={{ display: 'flex', gap: matchesSmallestWidth ? '3rem' : '5rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                <Typography variant="h6" fontWeight={600} paddingTop={1} paddingBottom={1}>Order Total</Typography>
                <Typography variant="h6" fontWeight={600} paddingTop={1}>${purchasePrice}</Typography>
              </div>
            </div>
          </div>
        </div>
      </>}
      {nTokensToPurchase > 0 && !checkout && <>
        <div style={{ position: 'relative' }}>
          <Button
            variant="contained"
            onClick={() => setCheckout(true)}
            sx={{
              background: Enum.WebsiteColors.Green,
              width: '275px',
              height: '40px'
            }}>
            Continue to Checkout
          </Button>
        </div>
      </>}
      {checkout && <>
        {!matchesSmallestWidth &&
          <Button
            variant="text"
            sx={{ color: 'black', marginRight: '.5rem' }}
            onClick={() => setCheckout(false)}
          >
            <ArrowBackIcon />
          </Button>
        }
        <PayPal
          coinPrice={coinPrice.toString()}
          quantity={nTokensToPurchase.toString()}
          value={(coinPrice * nTokensToPurchase).toFixed(2).toString()}
        />
      </>}
      {/* </>} */}
    </MainPanelContainer>
  )
}
