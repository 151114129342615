import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

const theme = createTheme({
	palette: {
		orange: {
			main: 'orange',
			contrastText: '#fff',
		},
	},
});
const StyledTypography = styled(Typography)`
	text-align: center; 
	font-family: Roboto; 
	color: white;
	padding: .5rem 2rem;
`;
const WebsiteButton = styled(Button)`
	display: block;
	width: 250px;
	height: 50px;
	color: black;
	background: black;
`;
const ImagePanelOverlayContainer = styled.div`
  margin-top: -55vh; 
  height: 55vh;
`;

export default function ImagePanelOverlay({ homeMatchesSmallestWidth }) {
	return (
		<ImagePanelOverlayContainer>
			<StyledTypography style={{ color: 'white', fontWeight: 500 }} variant={homeMatchesSmallestWidth ? "h4" : "h3"} component="div">
				Family Design & Planning Service
			</StyledTypography>
			<StyledTypography
				variant={homeMatchesSmallestWidth ? "h6" : "h5"}
				component="div"
				style={{ marginTop: '1rem', maxWidth: '50rem', marginLeft: 'auto', marginRight: 'auto', fontWeight: 400 }}
			>
				Bringing together the best modern practices all in one place to help you create a happy,
				healthy family using the world's best data and a caring support team.
			</StyledTypography>
			<Box textAlign='center' style={{ marginTop: '1rem', color: 'white' }}>
				<ThemeProvider theme={theme}>
					<WebsiteButton
						sx={{
							color: 'black',
							background: 'white',
							fontSize: '1rem',
							zIndex: 1,
							position: 'relative',
							"&:hover": {
								background: 'white',
								color: 'white',
								transition: '1500ms ease all',
							},
							"&:before, &:after": {
								content: '""',
								position: 'absolute',
								top: 0,
								left: 0,
								height: '100%',
								width: 0,
								background: 'black',
								transition: '400ms ease all',
								zIndex: -1,
								borderRadius: '.25rem',
							},
							"&hover:before, &:hover:after": {
								width: '100%',
								transition: '800ms ease all',
								color: 'white',
							}
						}}
						variant="contained"
						href="/"
					>
						Go to M21F
					</WebsiteButton>
				</ThemeProvider>
			</Box>
		</ImagePanelOverlayContainer>
	)
}
