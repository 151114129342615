import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import Question from "./Question";
import './AnimatedCheckmark.css';
import ClearIcon from '@mui/icons-material/Clear';
import ApiService from "../../../../services/ApiService";
import CheckMark from "./CheckMark";

const QuestionStepperContainer = styled.div`
  width: max-content; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 1rem; 
  position: relative; 
  max-width: 1000px; 
  overflow-x: auto;
  border: 2px solid lightgrey;
  border-radius: 1rem;
  background: d2d2d2;
`;
const HideQuestionsButton = {
  position: 'absolute',
  right: 10,
  color: 'black',
}

export default function QuestionStepper({
  contentAccountInfoReady,
  feedSetQuestionStepperClosed,


}) {
  // responses is a list of dicts containing keys: idQuestion, idAnswer, answerText
  const [responses, setResponses] = useState([])
  const [submitChecked, setSubmitChecked] = useState(false)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    if (localStorage.getItem('questionAnswered', true)) return
    if (localStorage.getItem("token") == null) return

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    ApiService.getSurveyQuestions(options)
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.questions)
      })
      .catch((err) => console.error(err))
  }, [])

  const submit = () => {
    setSubmitChecked(true)

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        'responses': responses,
      })
    }
    ApiService.saveSurveyResponse(options)
      .catch((err) => console.error(err))

    setTimeout(function() {
      localStorage.setItem('questionAnswered', true)
      feedSetQuestionStepperClosed(true)
    }, 3000)
  }

  const supportedDisplay = ["checkbox", "dropdown"]
  const supportedQuestions = questions?.filter(question => supportedDisplay.includes(question.display))

  return (
    <>
      {questions && questions.length > 0 &&
        <QuestionStepperContainer>
          <Button
            sx={HideQuestionsButton}
            startIcon={<ClearIcon />}
            onClick={() => feedSetQuestionStepperClosed(true)}
          />

          {submitChecked && <CheckMark />}

          {responses.length === supportedQuestions.length &&
            <Button
              variant="contained"
              style={{ 
                position: 'absolute', 
                bottom: 10, 
                left: 'calc(50% - 2.1875rem)' 
              }}
              onClick={() => submit()}
            >
              Submit
            </Button>
          }
          <Grid
            sx={{
              flexGrow: 1,
              width: 'max-content',
              m: '1rem 0',
              mr: 2
            }}
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                spacing={2}
              >
                {supportedQuestions?.map((question, index) => (
                  <Grid key={index} item>
                    <Question
                      question={question}
                      setResponses={setResponses}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </QuestionStepperContainer>
      }
    </>
  )
}
