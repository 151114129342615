import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import { traits } from './PersonalizationUtil';

const LabelTypographyWithMargin = {
  fontWeight: '600', 
  marginTop: '1.5rem',
}

export default function Trait(props) {
  return (
    <FormControl sx={{ ml: '1rem', mr: '1rem', width: props.smallWidth ? '100%' : 300 }}>
      <Typography variant="h7" component="div" sx={LabelTypographyWithMargin}>
        {props.label}
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue=""
        value={props.trait}
        onChange={(event) => props.setTrait(event)}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {traits.map((trait) =>
          <MenuItem value={trait}>{trait}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}