import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Enum from "../../../../enums/Enum";
import { IconButton } from "@mui/material";
import DeletePostDialog from "../../../Dialog/DeletePostDialog";
import InternalPostDialog from "../../../Dialog/InternalPostDialog";

const PostBodyTypography = {
  fontFamily: 'Roboto',
  fontSize: '1.25rem',
  textAlign: 'left'
}
const PostBodyTypographySmall = {
  fontFamily: 'Roboto',
  fontSize: '1rem',
  textAlign: 'left'
}
const PostTitleTypography = {
  marginBottom: '1rem',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Roboto'
}
const PostCardActionsContainer = {
  float: 'left'
}
const theme = createTheme()
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
};
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
};

export default function InternalPost({
  contentSavedPosts,
  contentMatchesSmallestWidth,
  color,
  post,
  reloadInternalPosts,
}) {
  const [editPostDialogOpen, setEditPostDialogOpen] = useState(false)
  const [deletePostDialogOpen, setDeletePostDialogOpen] = useState(false)

  const PostCardStyle = {
    marginBottom: '.1rem',
    marginTop: '1.5rem',
    width: contentMatchesSmallestWidth ? '100%' : 'calc(100% - 6rem)',
    paddingLeft: contentMatchesSmallestWidth ? 'initial' : '3rem',
    paddingRight: contentMatchesSmallestWidth ? 'initial' : '3rem',
    position: 'relative',
    zIndex: '10',
    wordWrap: 'break-word'
  }

  const userIsAuthor = localStorage.getItem('username') === post.data.author

  return (
    <>
      <Card style={PostCardStyle}>
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography variant="h5" component="div" style={PostTitleTypography}>
              {post.data.title}
            </Typography>
            <Typography
              variant="body2"
              style={contentMatchesSmallestWidth ? PostBodyTypographySmall : PostBodyTypography}
            >
              {post.data.body}
            </Typography>
          </ThemeProvider>
        </CardContent>
        <CardActions style={PostCardActionsContainer}>
          <ArrowUpwardIcon
            sx={{
              p: '5px',
              color: 'grey'
            }}
          />
          0 {post.data.upvotes}
          <ArrowDownwardIcon
            sx={{
              p: '5px',
              color: 'grey'
            }}
          />
          <div style={{ marginRight: '10px', fontFamily: 'Roboto' }}>
            0 {post.data.downvotes}
          </div>
          {userIsAuthor &&
            <>
              <IconButton onClick={() => setEditPostDialogOpen(true)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => setDeletePostDialogOpen(true)}>
                <DeleteIcon
                  sx={{
                    color: Enum.WebsiteColors.Maroon
                  }}
                />
              </IconButton>
            </>
          }
        </CardActions>
      </Card>
      {userIsAuthor &&
        <>
          {editPostDialogOpen &&
            <InternalPostDialog
              setDialogOpen={setEditPostDialogOpen}
              reloadInternalPosts={reloadInternalPosts}
              editMode={true}
              postToEdit={post}
            />
          }
          {deletePostDialogOpen &&
            <DeletePostDialog
              setDialogOpen={setDeletePostDialogOpen}
              reloadPosts={reloadInternalPosts}
              postToDelete={post}
            />
          }
        </>
      }
    </>
  )
}
