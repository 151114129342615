
export const traits = [
  'Active', 'Cheerful', 'Ambitious', 'Content', 
  'Patient', 'Expressive', 'Nurturing', 'Adventurous', 
  'Proper', 'Magisterial', 'Visionary', 'Optimistic', 
  'Harmonizer', 'Grateful', 'Inquisitive', 'Loyal', 
  'Devoted', 'Dependable', 'Encouraging', 'Sentimental', 
  'Forgiving', 'Gentle', 'Innovative', 'Accepting', 
  'Modest', 'Trusting', 'Fair'
]
