import React from "react";
import HomePageSidePanel from "./HomePageSidePanel/HomePageSidePanel";

export default function SidePanel({
  appTheme,
  appSetTheme,
  handleMenuClick,
}) {
  return (
    <HomePageSidePanel
      appTheme={appTheme}
      appSetTheme={appSetTheme}
      handleMenuClick={handleMenuClick}
    />
  )
}
