import React, { useEffect, useState } from "react";
import { createTheme, alpha, styled as muistyled } from '@mui/material/styles';
import styled from "styled-components";
import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ApiService from "../../../services/ApiService";
import LoginDialog from "../../Dialog/LoginDialog";
import RedditIcon from '../../../resources/reddit-icon.png';
import YoutubeIcon from '../../../resources/youtube-icon.png';
import EmailIcon from '../../../resources/email-icon.png';
import Enum from "../../../enums/Enum";

const theme = createTheme()
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
}
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
}
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
}
const GreenSwitch = muistyled(Switch)(({ theme, bgcolor }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: bgcolor,
    '&:hover': {
      backgroundColor: alpha(bgcolor, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: bgcolor,
  },
}))

export default function Filter({
  fromDialog,
  appTheme,
  appSetTheme,
  topicmainLoaded,
  topicmainShowBackgroundImage,
  topicmainToggleShowBackgroundImage,
  contentMatchesSmallestWidth,
  handleDropdownChange,
  handleCheckboxChange,
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  let color = appTheme === 'Default' ? Enum.WebsiteColors.Green :
    appTheme === 'Maroon' ? Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet

  const FilterContainer = {
    height: 'fit-content',
    width: 'fit-content',
    background: fromDialog ? 'white' : 'rgb(250, 250, 250)',
    boxShadow: 'none',
    padding: '0rem 1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  }

  const isUserAuthenticated = () => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return ApiService.isUserAuthenticated(options)
  }

  useEffect(() => {
    if (localStorage.getItem("token") == null) return;
    isUserAuthenticated()
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'Success') {
          setIsAuthenticated(true)
        }
      })
      .catch((err) => console.error(err))
  }, [])

  
  return (
    <div>
      {(topicmainLoaded || fromDialog) &&
        <div style={{ marginTop: '.5rem', height: 'fit-content' }}>
          <Card sx={FilterContainer}>
            <Box sx={{ display: 'flex', flexDirection: contentMatchesSmallestWidth ? 'column' : 'row', justifyContent: 'center' }}>
              <div>
                <FormControlLabel
                  label={
                    <img
                      src={YoutubeIcon}
                      alt="Youtube logo"
                      width={'auto'}
                      height={contentMatchesSmallestWidth ? '30px' : '40px'}
                      style={{ marginTop: '.5rem' }}
                    />
                  }
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(e, 'Videos')}
                      style={{ color: color }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <img
                      src={RedditIcon}
                      alt="Reddit logo"
                      width={'auto'}
                      height={contentMatchesSmallestWidth ? '30px' : '40px'}
                      style={{ marginTop: '.5rem' }}
                    />
                  }
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(e, 'Reddit')}
                      style={{ color: color }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <img
                      src={EmailIcon}
                      alt="Email logo"
                      width={'auto'}
                      height={contentMatchesSmallestWidth ? '30px' : '40px'}
                      style={{ marginTop: '.5rem' }}
                    />
                  }
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(e, 'Emails')}
                      style={{ color: color }}
                    />
                  }
                />
              </div>
              {showLoginDialog && !isAuthenticated && <LoginDialog setShowLoginDialog={setShowLoginDialog} />}
            </Box>
          </Card>
        </div>}
    </div>
  )
}
