import ApiService from '../../services/ApiService';

export const getQuestions = (setFirstPrompt, setSecondPrompt, setThirdPrompt, setFourthPrompt) => {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  ApiService.getAdminData(options)
    .then((res) => res.json())
    .then((data) => {
      setFirstPrompt(data.profile_question_one)
      setSecondPrompt(data.profile_question_two)
      setThirdPrompt(data.profile_question_three)
      setFourthPrompt(data.profile_question_four)
    })
    .catch((err) => console.error(err))
}

export function getAccountInfo(setUserInfo) {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  if (localStorage.getItem("token") != null) {
    ApiService.getAccountInfo(options)
      .then((res) => res.json())
      .then((data) => {
        setUserInfo(current => {
          return { ...current, email: data.email }
        })
        setUserInfo(current => {
          return { ...current, username: data.username }
        })
        setUserInfo(current => {
          return { ...current, about: data.about }
        })
        setUserInfo(current => {
          return { ...current, subbedTopics: data.subbed_topics }
        })
        localStorage.setItem('email', data.email)
        localStorage.setItem('username', data.username)
      })
      .catch((err) => console.error(err))
  }
}

export function updateAccount(userInfo) {
  let fields = {
    username: userInfo.username,
    email: userInfo.email,
    about: userInfo.about,
    subbed_topics: userInfo.subbedTopics,
  }
  return ApiService.updateAccount({
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    },
    body: JSON.stringify(fields)
  })
}
