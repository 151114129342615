import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Filter from "../MainPanel/TopicPage/Filter";
import Enum from "../../enums/Enum";


export default function FilterDialog({
  appTheme,
  appSetTheme,
  topicmainLoaded,
  topicmainShowBackgroundImage,
  topicmainToggleShowBackgroundImage,
  contentMatchesSmallestWidth,
  feedSetFilterDialogOpen,
  handleDropdownChange,
  handleCheckboxChange,
}) {
  return (
    <Dialog
      open={true}
      onClose={() => feedSetFilterDialogOpen(false)}
      aria-labelledby="question-dialog-title"
    >
      <DialogContent sx={{ paddingTop: 0 }}>
        <Filter
          fromDialog={true}
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          topicmainLoaded={topicmainLoaded}
          topicmainShowBackgroundImage={topicmainShowBackgroundImage}
          topicmainToggleShowBackgroundImage={topicmainToggleShowBackgroundImage}
          contentMatchesSmallestWidth={contentMatchesSmallestWidth}
          handleDropdownChange={handleDropdownChange}
          handleCheckboxChange={handleCheckboxChange}
        />
      </DialogContent>
      <DialogActions style={{ marginRight: '1rem', marginBottom: '.5rem' }}>
        <Button
          onClick={() => feedSetFilterDialogOpen(false)}
          style={{ color: Enum.WebsiteColors.Green }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
