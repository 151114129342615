import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import Enum from "../../enums/Enum";


export default function LoginDialog({ setShowLoginDialog, featureDark }) {
  return (
    <Dialog
      open={true}
      onClose={() => setShowLoginDialog(false)}
      aria-labelledby="question-dialog-title"
    >
      {featureDark ? 
        <DialogTitle>
          Looks like this feature isn't ready yet...
        </DialogTitle> 
      :
        <DialogTitle>
          Looks like you're not logged in!
        </DialogTitle>
      }
      {featureDark ? 
      <DialogContent sx={{ paddingTop: 0 }}>
        This awesome feature isn't ready yet,
        but stay tuned because we're working hard
        on it!
      </DialogContent>
      :
      <DialogContent sx={{ paddingTop: 0 }}>
        Would you like to login or create an account now so that you can have access
        to more cool features?
      </DialogContent>
      }
      {featureDark ? 
        <DialogActions style={{ marginRight: '1rem', marginBottom: '.5rem' }}>
          <Button 
            onClick={() => setShowLoginDialog(false)} 
            style={{ color: Enum.WebsiteColors.Green }}
          >
            Back
          </Button>
        </DialogActions>
        :
        <DialogActions style={{ marginRight: '1rem', marginBottom: '.5rem' }}>
          <Button 
            onClick={() => setShowLoginDialog(false)} 
            style={{ color: Enum.WebsiteColors.Green }}
          >
            No, thanks
          </Button>
          <Button
            href="/login"
            startIcon={<LoginIcon />} 
            onClick={() => setShowLoginDialog(false)} 
            style={{ color: Enum.WebsiteColors.Maroon, marginLeft: '1rem' }}
          >
            Login
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}