import { useEffect, useRef, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import SellIcon from '@mui/icons-material/Sell';

import { usePage, usePageUpdate } from "../../PageContext";
import ApiService from "../../services/ApiService";
import Enum from "../../enums/Enum";
import { transformPost } from '../../utils/PostTransform';


export default function TagSearch({
  appviewPosts,
  appviewSetPosts,
  appviewSetReloadFeed,
  toolbarMatchesSmallestWidth,
  toolbarMatchesMedWidth,
  toolbarSearchTerm,
  toolbarSetSearchTerm,
  toggleSearchType,
}) {

  const tagRef = useRef(new Set());
  const activePage = usePage()
  const updatePage = usePageUpdate()
  const [open, setOpen] = useState(false);

  useEffect(() => {
    ApiService.getTags()
      .then(response => response.json())
      .then(data => {
        data.tags.forEach(((tag) => {
          tagRef.current = new Set(tagRef.current.add(tag));
        }));
      })
  }, [])

  const OPTIONS_LIMIT = 5;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
  })

  const handleOnChange = (_e, value) => {
    if (!value) return

    setOpen(false);

    appviewSetPosts(new Map());

    ApiService.getAllPostsWithTag(value)
      .then(res => res.json())
      .then(data => {
        data.posts.forEach((post) => {
          if (post) {
            appviewSetPosts((prevPosts) => (new Map(prevPosts.set(post.id, transformPost(post)))));
          }
        })
        if (activePage !== Enum.Topics.Search) {
          updatePage(Enum.Topics.Search);
        } else {
          appviewSetReloadFeed(true);
        }
      })
      .catch(err => console.error(err))
    toolbarSetSearchTerm(value);

  }

  const handleOnKeyUp = (e) => {
    let value = e.target.value;
    if (value && value.length > 0) {
      setOpen(true);
      toolbarSetSearchTerm(value);
    } else {
      setOpen(false);
    }
  }

  return (
    <Autocomplete
      id="tags-outlined"
      options={Array.from(tagRef.current)}
      sx={{ width: toolbarMatchesSmallestWidth ? 250 : toolbarMatchesMedWidth ? 400 : 600, pt: '.5rem', pb: '.5rem' }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(e, value) => handleOnChange(e, value)}
      onKeyUp={(e) => handleOnKeyUp(e)}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ background: 'white', mt: 0, mb: 0, borderRadius: '.25rem' }}
          placeholder="Search Everywhere by Tags"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <IconButton sx={{ fontSize: '1rem', borderRadius: '0px' }} onClick={toggleSearchType}>
                    {!toolbarMatchesSmallestWidth &&
                      <Typography sx={{ mr: 1 }}>
                        Search by: Tags
                      </Typography>
                    }
                    <SellIcon />
                  </IconButton>
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

