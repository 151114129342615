import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Input, TextField } from '@mui/material';
import Enum from '../../enums/Enum';
import ApiService from '../../services/ApiService';

export default function InternalPostDialog({ setDialogOpen, reloadInternalPosts, editMode, postToEdit }) {
  const [title, setTitle] = useState()
  const [body, setBody] = useState()

  const content = JSON.stringify({
    'post': postToEdit,
    'title': title,
    'body': body,
    'user': localStorage.getItem('username')
  })

  const headers = {
    'Content-Type': 'application/json'
  }

  useEffect(() => {
    if (editMode) {
      setTitle(postToEdit.data.title)
      setBody(postToEdit.data.body)
    }
  }, [])

  const createPost = () => {
    ApiService.createInternalPost({
      method: 'POST',
      headers: headers,
      body: content
    })
      .then((res) => res.json())
      .then((data) => {
        setDialogOpen(false)
        reloadInternalPosts(false)
      })
      .catch((err) => console.error(err))
  }

  const updatePost = () => {
    ApiService.updateInternalPost({
      method: 'PUT',
      headers: headers,
      body: content
    })
      .then((res) => res.json())
      .then((data) => {
        setDialogOpen(false)
        reloadInternalPosts(false)
      })
      .catch((err) => console.error(err))
  }

  return (
    <Dialog
      open={true}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {editMode ? 'Edit Post' : 'Create New Post'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Input
            placeholder="Post Title"
            inputProps={{
              'aria-label': 'description'
            }}
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div style={{ marginTop: '2rem' }}>
            <TextField
              id="filled-multiline-static"
              multiline
              rows={4}
              placeholder="Post Body"
              variant="filled"
              fullWidth
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setDialogOpen(false)}
          sx={{
            color: Enum.WebsiteColors.Maroon,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => { editMode ? updatePost() : createPost() }}
          autoFocus
          sx={{
            color: Enum.WebsiteColors.Green
          }}
        >
          {editMode ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
