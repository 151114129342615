import React, { useState, useEffect } from "react";
import styled from "styled-components";
import familyRight from '../../resources/familyright.png'
import familyLeft from '../../resources/familyleft.png'

const ImagePanelContainer = styled.div(({ backgroundImage }) => ({
	height: '75vh',
	marginTop: '-2rem', 
	zIndex: '-1', 
	backgroundColor: '#5865F2',
	objectFit: 'cover',
	position: 'relative'
}));

export default function ImagePanel() {
	const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 850px)").matches
  )
	const [matchesSmallWidth, setMatchesSmallWidth] = useState(
    window.matchMedia("(max-width: 1125px)").matches
  )
	const [matchesMedWidth, setMatchesMedWidth] = useState(
    window.matchMedia("(max-width: 1500px)").matches
  )
	const [matchesSmallHeight, setMatchesSmallHeight] = useState(
    window.matchMedia("(max-height: 750px)").matches
  )
	const [matchesMedHeight, setMatchesMedHeight] = useState(
    window.matchMedia("(max-height: 1000px)").matches
  )

	useEffect(() => {
		window
			.matchMedia("(max-width: 850px)")
			.addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
		window
			.matchMedia("(max-width: 1125px)")
			.addEventListener('change', e => {
        setMatchesSmallWidth(e.matches)
      })
    window
			.matchMedia("(max-width: 1500px)")
			.addEventListener('change', e => {
        setMatchesMedWidth(e.matches)
      })
		window
			.matchMedia("(max-height: 750px)")
			.addEventListener('change', e => {
        setMatchesSmallHeight(e.matches)
      })
		window
			.matchMedia("(max-height: 1000px)")
			.addEventListener('change', e => {
        setMatchesMedHeight(e.matches)
      })
  }, [])

	return (
		<ImagePanelContainer>
			<div style={{ maxWidth: '1000px', width: matchesSmallestWidth ? '400px' : 'initial', position: 'relative', height: '100%', marginRight: 'auto', marginLeft: 'auto' }}>
				{!(matchesMedHeight && matchesSmallestWidth) && !matchesSmallHeight &&
					<img
						src={familyLeft}
						alt="family left"
						width={'400px'}
						height={'auto'}
						style={{ position: 'absolute', bottom: 0, left: 0 }}
					/>
				}
				{!(matchesMedHeight && matchesSmallestWidth) && !matchesSmallHeight &&
					<img
						src={familyRight}
						alt="family right"
						width={'400px'}
						height={'auto'}
						style={{ position: 'absolute', bottom: 0, right: 0 }}
					/>
				}
			</div>
		</ImagePanelContainer>	
	)
}