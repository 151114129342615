import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const CommentCardStyle = {
  width: 'calc(100% - 4rem)',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginBottom: '.25rem'
}
const CommentCardStyleSmall = {
  width: '100%',
  marginBottom: '.25rem'
}
const CommentLabelTypography = {
  marginBottom: '1rem'
}
const CommentPersonIcon = {
  fontSize: '5rem',
  float: 'left'
}
const CommentPersonIconSmall = {
  fontSize: '4rem',
  float: 'left'
}
const CommentUserTypography = {
  marginBottom: '.25rem'
}
const CommentBodyTypography = {
  fontSize: '1.25rem',
  fontFamily: 'Roboto'
}
const CommentBodyTypographySmall = {
  fontSize: '1rem',
  fontFamily: 'Roboto'
}
const CommentContainer = styled.div`
  margin-bottom: 10px;
`;
const theme = createTheme();
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
};
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
};

export default function RedditComment({
  contentMatchesSmallestWidth,
  post
}) {

  const getCommentGifUrl = (comment) => {
    let url = 'https://giphy.com/embed/' +
      comment.substring(getPosition(comment, '|', 1) + 1, getPosition(comment, '|', 2) - 1)

    if (url.endsWith(')')) {
      url.slice(0, -1)
    }
    return url
  }

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  return (
    <Accordion style={{ textAlign: 'left' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Comments</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Card style={contentMatchesSmallestWidth ? CommentCardStyleSmall : CommentCardStyle} key="card">
          <CardContent>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h6"
                component="div"
                style={CommentLabelTypography}
              >
                Topics {'>'} {post.data.subreddit} {'>'} Comments
              </Typography>
            </ThemeProvider>
            {post.data.comments && post.data.comments.map(comment =>
              <CommentContainer key={comment.id}>
                <PersonIcon style={contentMatchesSmallestWidth ? CommentPersonIconSmall : CommentPersonIcon} />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={CommentUserTypography}
                  >
                    {comment.author}
                  </Typography>
                  {comment.body.startsWith('![gif]') ?
                    <iframe
                      sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
                      id="iframe"
                      src={getCommentGifUrl(comment.body)}
                      style={{ border: 'none', width: '150px' }}
                    />
                    :
                    <Typography
                      variant="body2"
                      style={contentMatchesSmallestWidth ? CommentBodyTypographySmall : CommentBodyTypography}
                    >
                      {comment.body}
                    </Typography>
                  }
                </ThemeProvider>
                <CardActions>
                  <ArrowUpwardIcon sx={{ p: '5px', color: 'grey' }} />
                  {comment.score}
                </CardActions>
                <Divider />
              </CommentContainer>
            )}
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}
