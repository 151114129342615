import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

export default function ProfileQuestion({ index, profileQuestions, questionsUploadedFromFile, updateProfileQuestions, moreAboutYouQuestions }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (moreAboutYouQuestions) {
      setValue(moreAboutYouQuestions[index])
    }
  }, [moreAboutYouQuestions])

  useEffect(() => {
    if (questionsUploadedFromFile && questionsUploadedFromFile[index]) {
      setValue(questionsUploadedFromFile[index])
    }
  }, [questionsUploadedFromFile])

  return (
    <TextField 
      id="outlined-basic" 
      variant="outlined" 
      multiline maxRows={4}
      fullWidth
      sx={{ mt: 1 }} 
      value={value} 
      onChange={(e) => {
        setValue(e.target.value)
        updateProfileQuestions(index, e.target.value)
      }} 
    />
  )
}