import Enum from "../enums/Enum";

export function transformPost(currentPost) {
  let currentComments = [];
  if (currentPost.comments !== undefined) {
    currentComments = currentPost.comments.filter(
      comment => comment.user !== 'AutoModerator' &&
        !comment.user.toLowerCase().includes('bot'));
  }

  let post = {
    id: currentPost.id,
    data: {
      body: currentPost.body,
      comments: currentComments,
      title: currentPost.title,
      url: currentPost.url,
      time: currentPost.time,
      description: currentPost.description,
    }
  }

  // Reddit
  if (currentPost.source === "Reddit") {
    post.source = Enum.Sources.Reddit;
    post.data.mId = currentPost.mId;
    post.data.author = currentPost.user;
    post.data.subreddit = currentPost.subreddit;
    post.data.upvotes = currentPost.upvotes;
    post.data.downvotes = currentPost.downvotes;
  }

  // Email
  if (currentPost.source === "Gmail") {
    post.source = Enum.Sources.Gmail;
    post.data.mId = currentPost.mId;
  }

  // Youtube
  if (currentPost.source === "Youtube") {
    post.source = Enum.Sources.Youtube;
    post.data.mId = currentPost.mId;
  }

  // Internal
  if (currentPost.source === "Internal") {
    post.source = Enum.Sources.Internal;
    post.data.mId = currentPost.mId;
  }

  return post;
}
