import { useState, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Enum from '../../enums/Enum';

const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))
const MainPanelContainer = styled.div`
  width: 100%;
  padding: 1.5rem 4rem;
  @media (max-width: 1450px) {
    padding: 1.5rem 1rem;
  }
`;

export default function PrivacyPage({
  settingsSidebarOpen,
  handleToggleSidebar,
}) {

  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
  }, [])

  return (
    <MainPanelContainer>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '1rem' }}
      >
        {matchesSmallestWidth && !settingsSidebarOpen &&
          <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
            <Grid item xs={1}>
              <Button
                variant="text"
                sx={{ color: 'black', marginRight: '.5rem' }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </BackArrowContainer>
        }
        <Grid item xs={8}>
          <Typography
            variant={matchesSmallestWidth ? "h5" : "h4"}
            component="div"
            sx={{ fontWeight: '900', paddingLeft: '0px' }}
          >
            Privacy & Security
          </Typography>
        </Grid>
      </Grid>
    </MainPanelContainer>
  )
}
