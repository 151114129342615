import React, { useState, useEffect } from "react";
import MainPanel from "../MainPanel/MainPanel.js";
import SidePanel from "../SidePanel/SidePanel.js";
import { AppContainer } from "../styles/AppContainer.styled.js";
import { SidePanelContainer } from "../styles/SidePanelContainer.styled.js";
import { PageProvider } from "../../PageContext.js";
import Toolbar from "../Toolbar/Toolbar.js";
import ApiService from "../../services/ApiService";

export default function AppView({ appTheme, appSetTheme }) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [posts, setPosts] = useState(new Map())  // ordered map of post ids to the post
  const [tags, setTags] = useState(new Set())  // set of tags
  const [topics, setTopics] = useState({})  // map of topic names to topicId
  const [reloadFeed, setReloadFeed] = useState(false)
  let sidePanelBgColor = '#f4f4f4'

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  useEffect(() => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    // `getAllTopics` returns a list of {id, name}
    ApiService.getAllTopics(options)
      .then((res) => res.json())
      .then((data) => {
        setTopics( // convert list of {id, name} into {name: id}
          data.reduce((topicMap, obj) => {
            topicMap[obj.name] = obj.id;
            return topicMap
          }, {})
        )
      })
  }, [])

  const handleMenuClick = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <PageProvider>
      <Toolbar
        appTheme={appTheme}
        appviewSidebarOpen={sidebarOpen}
        appviewPosts={posts}
        appviewSetPosts={setPosts}
        appviewTags={tags}
        appviewSetTags={setTags}
        appviewTopics={topics}
        appviewSetReloadFeed={setReloadFeed}
        handleMenuClick={handleMenuClick}
        callingFrom={'AppView'}
      />
      <AppContainer>
        <SidePanelContainer sidebarOpen={sidebarOpen} bgColor={sidePanelBgColor}>
          <SidePanel
            appTheme={appTheme}
            appSetTheme={appSetTheme}
            handleMenuClick={handleMenuClick}
          />
        </SidePanelContainer>
        <MainPanel
          appTheme={appTheme}
          appSetTheme={appSetTheme}
          appviewTags={tags}
          appviewSetTags={setTags}
          appviewPosts={posts}
          appviewSetPosts={setPosts}
          appviewTopics={topics}
          appviewReloadFeed={reloadFeed}
          appviewSetReloadFeed={setReloadFeed}
        />
      </AppContainer>
    </PageProvider>
  )
}
