import { Button, Checkbox, FormControl, FormControlLabel, Grid, Typography, chipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from './Questions/Snackbar';
import ApiService from '../../services/ApiService';
import Enum from '../../enums/Enum';

const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))

export default function TopicSubs({
  settingsUserInfo,
  settingsSetUserInfo,
  settingsSidebarOpen,
  settingsSnackbarOpen,
  settingsSetSnackbarOpen,
  settingsShowSuccessMessage,
  settingsmainMatchesSmallestWidth,
  settingsmainMatchesMedWidth,
  handleToggleSidebar,
  saveAccountInfo,
  color,
}) {
  const [topics, setTopics] = useState([])
  const [isChecked, setIsChecked] = useState(() => topics.map((i) => false))
  const [setupCompleted, setSetupCompleted] = useState(false)

  const MainPanelContainer = {
    width: '100%',
    padding: settingsmainMatchesMedWidth ? '1.5rem 1rem' : '1.5rem 4rem',
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    settingsSetSnackbarOpen(false)
  }

  const save = () => {
    saveAccountInfo()
  }

  useEffect(() => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    ApiService.getAllTopics(options)
      .then((res) => res.json())
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          setTopics((previous) => [...previous, data[i]])
        }
      })
      .catch((err) => console.error(err))
  }, [])


  useEffect(() => {
    if (isChecked.length === 0) {
      setIsChecked(() => topics.map((i) => false))
    }
    loadCheckBoxes()
  }, [topics])


  const loadCheckBoxes = () => {
    if (topics.length > 0 && settingsUserInfo.subbedTopics) {
      let topicNames = topics.map(topic => topic.name)
      for (let i = 0; i < settingsUserInfo.subbedTopics.length; i++) {
        if (topicNames.includes(settingsUserInfo.subbedTopics[i])) {
          setCheckboxChecked(topicNames.indexOf(settingsUserInfo.subbedTopics[i]), true)
        }
      }
      setSetupCompleted(true)
    }
  }


  const setCheckboxChecked = (index, checked) => {
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked
        return c
      })
    })
  }


  useEffect(() => {
    if (setupCompleted) {
      let topicNames = topics.map(topic => topic.name)
      let checkedTopics = topicNames.filter((topic) => {
        return isChecked[topicNames.indexOf(topic)]
      })
      if (checkedTopics) {
        settingsSetUserInfo(current => {
          return { ...current, subbedTopics: checkedTopics }
        })
      }
    }
  }, [isChecked])
  
  return (
    <div style={MainPanelContainer}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '1rem' }}
      >
        {settingsmainMatchesSmallestWidth && !settingsSidebarOpen &&
          <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
            <Grid item xs={1}>
              <Button
                variant="text"
                sx={{
                  color: 'black',
                  mr: '.5rem'
                }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </BackArrowContainer>
        }
      </Grid>
      <Typography
        variant={settingsmainMatchesSmallestWidth ? "h5" : "h4"}
        component="div"
        sx={{
          fontWeight: '900',
          pl: '0px'
        }}
      >
        Topic Subs
      </Typography>
      <Typography
        variant="subtitle"
        sx={{
          color: 'grey',
        }}
      >
        Pick the topics that matter to you
      </Typography>
      <br />
      <br />
      <FormControl component="fieldset">
        {topics.map((topic, index) => (
          <FormControlLabel
            key={topic.id}
            control={
              <Checkbox
                sx={{
                  padding: .5,
                  color: Enum.WebsiteColors.Green,
                  '&.Mui-checked': {
                    color: Enum.WebsiteColors.Green,
                  }
                }}
                checked={isChecked[index] || false}
                onClick={(e) => setCheckboxChecked(index, e.target.checked)}
                inputProps={{
                  'aria-label': 'controlled'
                }}
              />
            }
            label={topic.name}
          />
        ))}
        {topics && topics.length > 0 &&
          <Button
            style={{
              marginTop: '2rem',
              background: color,
              color: 'white',
              padding: '.3rem',
              boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              onClick={() => save()}
              sx={{
                "&:hover": {
                  background: color
                }
              }}
            >
              <Grid item xs={12}>
                Save
              </Grid>
            </Grid>
          </Button>
        }
      </FormControl>
      <Snackbar
        snackbarOpen={settingsSnackbarOpen}
        autoHideDuration={1000}
        handleCloseSnackbar={handleCloseSnackbar}
        showSuccessMessage={settingsShowSuccessMessage}
      />
    </div>
  )
}
