import styled from "styled-components";

export const TopicButtonGroupContainer = styled.div`
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 100%);
    @media (min-width: 2301px) {
        grid-template-areas:
            "recommended"
            "fertility"
            "family"
            "planning"
            "repro-tech"
            "legal"
            "logistics"
            "community"
            "saved";
    }
    @media (max-width: 2300px) {
        grid-template-areas:
            "recommended"
            "fertility"
            "family"
            "planning"
            "repro-tech"
            "legal"
            "logistics"
            "community"
            "saved";
        align-items: center;
    }
    @media (max-width: 1000px) {
        margin-left: 10rem;
        margin-right: 10rem;
        grid-template-areas:
            "recommended"
            "fertility"
            "family"
            "planning"
            "repro-tech"
            "legal"
            "logistics"
            "community"
            "saved";
        align-items: center;
    }
    @media (max-width: 750px) {
        margin-left: 2rem;
        margin-right: 2rem;
        grid-template-areas:
            "recommended"
            "fertility"
            "family"
            "planning"
            "repro-tech"
            "legal"
            "logistics"
            "community"
            "internalPosts";
        align-items: center;
    }
`
