import { useState, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Enum from '../../enums/Enum';

const WhoAreWe = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '1.5rem',
  fontWeight: 600,
  marginBottom: '1rem',
}
const GridContainers = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '1.4rem',
  background: 'rgb(31, 102, 119)',
  height: '100%',
  fontWeight: 'bold',
  borderRadius: '5px',
  padding: '2.5rem 1.5rem',
}
const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))

export default function AboutPage({
  settingsSidebarOpen,
  handleToggleSidebar,
}) {
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )
  const [matchesMedWidth, setMatchesMedWidth] = useState(
    window.matchMedia("(max-width: 2200px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
    window
      .matchMedia("(max-width: 2200px)")
      .addEventListener('change', e => {
        setMatchesMedWidth(e.matches)
      })
  }, [])

  const MainPanelContainer = {
    width: '100%',
    height: 'fit-content',
    padding: matchesSmallestWidth ? '1.5rem 0' : matchesMedWidth ? '1.5rem 1rem' : '1.5rem 4rem',
  }
  const SectionStyle = {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: matchesSmallestWidth ? '1.1rem' : '1.4rem',
    lineHeight: '1.5'
  }
  const ContentContainer = {
    background: 'rgb(0, 71, 88)',
    padding: matchesSmallestWidth ? '1.5rem' : '2rem',
    borderRadius: '.625rem'
  }

  return (
    <div style={MainPanelContainer}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '1rem' }}
      >
        {matchesSmallestWidth && !settingsSidebarOpen &&
          <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
            <Grid item xs={1}>
              <Button
                variant="text"
                sx={{ color: 'black', marginRight: '.5rem' }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </BackArrowContainer>
        }
        <Grid item xs={1}>
          <Typography
            variant={matchesSmallestWidth ? "h5" : "h4"}
            component="div"
            sx={{ fontWeight: '900', paddingLeft: '0px' }}
          >
            About
          </Typography>
        </Grid>
      </Grid>
      <div style={ContentContainer}>
        <Grid
          container
          direction={matchesMedWidth ? "column" : "row"}
          style={{ marginBottom: '1rem' }}
        >
          <Grid item xs={2}>
            <Typography variant="h7" component="div" style={WhoAreWe}>
              Who are we?
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              component="div"
              style={{ color: 'white', fontFamily: 'Roboto', fontSize: matchesSmallestWidth ? '1.1rem' : '1.4rem', lineHeight: '1.5' }}
            >
              Modern21Family was designed by a small team at All Is One Sustainability in order to provide people
              with robust tools for planning out families. We wanted to make starting a family much easier for
              people, and enabling them to do so in a way that felt good for them and good for the planet.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Grid
            container
            spacing={2}
            direction={matchesMedWidth ? "column" : "row"}
            style={{ marginBottom: matchesMedWidth ? 0 : '5rem', marginTop: '2rem' }}
          >
            <Grid item xs={4}>
              <div style={GridContainers}>
                <Typography variant="h7" component="div">
                  Values
                </Typography>
                <Typography variant="h5" component="div"
                  style={SectionStyle}>
                  Our values are always sustainability first. That means we want diverse, healthy systems in
                  everything we do. Families are an incredibly important aspect of most peoples lives, and
                  making sure that our tools exemplify sustainable practices and design are important. To do
                  this, we focus on our core values every step of the way. We are inclusive, data driven,
                  connected and considerate.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={GridContainers}>
                <Typography variant="h7" component="div">
                  Our Goal
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={SectionStyle}
                >
                  Our goal is to make tools that help the world move towards a brighter, more sustainable future.
                  Whether that is for the individual, their homes, communities or the world, we aim to produce
                  high quality products and services in alignment with this goal.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={GridContainers}>
                <Typography variant="h7" component="div">
                  Accolades
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={SectionStyle}
                >
                  Our team consists of skilled individuals who are knowledgeable in areas of data, machine learning,
                  software development and scientific literature. We partner with experts in specialized fields to
                  bring quality content to our platforms so our members and users can trust in the information they
                  find to be modern, accurate, and as helpful as possible.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}
