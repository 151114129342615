import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { marks, valuetext, handleDialogClose } from '../MainPanel/TopicPage/TopicPageUtil';
import Enum from "../../enums/Enum";


export default function QuestionDialog({
  question,
  color,
}) {
  const [newWhenStartFamilyResponse, setNewWhenStartFamilyResponse] = useState()
  const [openDialog, setOpenDialog] = useState(true)

  const handleWhenStartFamilyChange = (event, newValue) => {
    const valueToStringDict = {
      0: 'Never',
      33: 'Not for a while',
      66: 'Soon',
      100: 'Now!'
    }
    setNewWhenStartFamilyResponse(valueToStringDict[newValue])
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleDialogClose(openDialog, newWhenStartFamilyResponse, setOpenDialog)}
      aria-labelledby="question-dialog-title"
    >
      <DialogTitle>
        How soon are you looking to start a family?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {question}
          <Box sx={{ width: '75%', ml: 'auto', mr: 'auto', mt: '2rem' }}>
            <Slider
              aria-label="Question Response Slider"
              defaultValue={0}
              value={newWhenStartFamilyResponse}
              onChange={handleWhenStartFamilyChange}
              getAriaValueText={valuetext}
              step={null}
              marks={marks}
              style={{ color: color }}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginRight: '1rem' }}>
        <Button
          onClick={() => handleDialogClose(openDialog, 'NA', setOpenDialog)}
          style={{ color: Enum.WebsiteColors.Green }}
        >
          Prefer not to say
        </Button>
        <Button
          onClick={() => handleDialogClose(openDialog, newWhenStartFamilyResponse, setOpenDialog)}
          style={{ color: Enum.WebsiteColors.Green }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
