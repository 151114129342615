import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { MORE_ABOUT_YOU_QUESTIONS, getMoreAboutYouJSX } from '../../../Datasources';
import Snackbar from './Snackbar';

export default function MoreInfo(props) {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  let selectedTraits = [ props.firstTrait, props.secondTrait, props.thirdTrait, props.fourthTrait ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const steps = MORE_ABOUT_YOU_QUESTIONS.map((question, index) => (
    {
      label: question,
      jsx: getMoreAboutYouJSX({
        firstTrait: props.firstTrait,
        setFirstTrait: props.setFirstTrait,
        secondTrait: props.secondTrait,
        setSecondTrait: props.setSecondTrait,
        thirdTrait: props.thirdTrait,
        setThirdTrait: props.setThirdTrait,
        fourthTrait: props.fourthTrait,
        setFourthTrait: props.setFourthTrait,
        matchesSmallestWidth: props.matchesSmallestWidth
      }, index)
    }
  ))

  const maxSteps = steps.length

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    
    props.setSnackbarOpen(false)
  }

  return (
    <div style={{ width: 'fit-content' }}>
       {/* props.matchesSmallestWidth ? '100%' : 'calc(83.33% - 1rem)' }}> */}
      <Box sx={{ width: props.matchesMedWidth ? 'fit-content' : 'initial', flexGrow: 1, mt: '1rem', border: '2px solid rgba(0, 0, 0, 0.12)' }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: props.color,
            color: 'white',
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
        <Box sx={{ height: '100%', minHeight: 250, p: 2 }}>
          <div style={{ width: 'fit-content' }}>
            {steps[activeStep].jsx}
            <div style={{ textAlign: 'center' }}>
              <Button 
                onClick={() => props.saveAccountInfo()} 
                style={{ 
                  width: props.matchesSmallestWidth ? '200px' : '300px', 
                  marginTop: '2rem',
                  background: props.color, 
                  color: 'white', 
                  padding: '.3rem',
                  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' 
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          style={{boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}}
          nextButton={
            <Button
              size="small"
              style={{ color: (activeStep === maxSteps - 1) ? 'grey' : props.color, paddingLeft: '1rem' }} 
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button 
              size="small" 
              style={{ color: activeStep === 0 ? 'grey' : props.color, paddingRight: '1rem' }} 
              onClick={handleBack} 
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
      <Snackbar 
        snackbarOpen={props.snackbarOpen} 
        autoHideDuration={1000} 
        handleCloseSnackbar={handleCloseSnackbar} 
        showSuccessMessage={props.showSuccessMessage} 
      />
    </div>
  )
}