import { useState, useEffect } from 'react';
import AboutPage from './AboutPage';
import AccountPage from './AccountPage';
import BillingPage from './BillingPage';
import NotificationPage from './NotificationPage';
import PrivacyPage from './PrivacyPage';
import SupportPage from './SupportPage';
import Personalization from './Questions/Personalization';
import TopicSubs from './TopicSubs';
import Enum from '../../enums/Enum';

export default function SettingsMainPanel({
  appCurrentSettingsPage,
  appTheme,
  settingsUserInfo,
  settingsSetUserInfo,
  settingsSidebarOpen,
  settingsShowSuccessMessage,
  settingsShowErrorMessage,
  settingsSnackbarOpen,
  settingsSetSnackbarOpen,
  saveAccountInfo,
  handleToggleSidebar,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )
  const [matchesMedWidth, setMatchesMedWidth] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  )

  let color = appTheme === 'Default' ? Enum.WebsiteColors.Green : appTheme === 'Maroon' ? 
                Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
  }, [])

  return (
    <>
      {matchesSmallestWidth && settingsSidebarOpen ? <></> :
        <>
          {appCurrentSettingsPage === 'Personalization' &&
            <Personalization
              settingsSidebarOpen={settingsSidebarOpen}
              settingsmainMatchesSmallestWidth={matchesSmallestWidth}
              handleToggleSidebar={handleToggleSidebar}
              color={color}
            />
          }
          {appCurrentSettingsPage === 'Notifications' &&
            <NotificationPage
              settingsSidebarOpen={settingsSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          {appCurrentSettingsPage === 'Privacy' &&
            <PrivacyPage
              settingsSidebarOpen={settingsSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          {appCurrentSettingsPage === 'Payments & Billing' &&
            <BillingPage
              settingsSidebarOpen={settingsSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          {appCurrentSettingsPage === 'Help & Support' &&
            <SupportPage
              settingsSidebarOpen={settingsSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          {appCurrentSettingsPage === 'About' &&
            <AboutPage
              settingsSidebarOpen={settingsSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          {appCurrentSettingsPage === 'Account' &&
            <AccountPage
              settingsSidebarOpen={settingsSidebarOpen}
              settingsSnackbarOpen={settingsSnackbarOpen}
              settingsSetSnackbarOpen={settingsSetSnackbarOpen}
              settingsUserInfo={settingsUserInfo}
              settingsSetUserInfo={settingsSetUserInfo}
              settingsShowSuccessMessage={settingsShowSuccessMessage}
              settingsmainMatchesSmallestWidth={matchesSmallestWidth}
              settingsmainMatchesMedWidth={matchesMedWidth}
              handleToggleSidebar={handleToggleSidebar}
              saveAccountInfo={saveAccountInfo}
              color={color}
            />
          }
          {appCurrentSettingsPage === 'Topic Subs' &&
            <TopicSubs
              settingsUserInfo={settingsUserInfo}
              settingsSetUserInfo={settingsSetUserInfo}
              settingsSidebarOpen={settingsSidebarOpen}
              settingsSnackbarOpen={settingsSnackbarOpen}
              settingsSetSnackbarOpen={settingsSetSnackbarOpen}
              settingsShowSuccessMessage={settingsShowSuccessMessage}
              settingsmainMatchesSmallestWidth={matchesSmallestWidth}
              settingsmainMatchesMedWidth={matchesMedWidth}
              handleToggleSidebar={handleToggleSidebar}
              saveAccountInfo={saveAccountInfo}
              color={color}
            />
          }
        </>
      }
    </>
  )
}
